import type { RouteLocationRaw, Router } from 'vue-router'
import AnalysisState from './AnalysisState'
import SearchBarState from './SearchBarState'
import State from './State'
import { reactive, type UnwrapNestedRefs } from 'vue'
import Axios from 'axios'
import { useI18nInstance } from '../services/I18n'
import { EN_LOCALE, LOCALES_TO_LANG_CODES } from '../models/constants'
import { errorLog, infoLog } from '../helpers/AppHelper'
import FeedbackFormState from './FeedbackFormState'
import { route } from '../helpers/RouteHelper'
import LandingState from './LandingState'
import { buidRouter } from '../boot/router'

export class AppState extends State {
  public router: Router

  public lang: string = EN_LOCALE

  public statsDataBundle: unknown

  private initialRouteVarians: Record<string, string>
  private initialRouteName: string | null
  private initialDomain: string | null
  private analizeSelectedBg: object | null

  private constructor () {
    super(null)
    this.autoStartChildren = false
    this.router = buidRouter()
    const searchBarState: State = new SearchBarState(this)
    searchBarState.reset()
    this.addChild('search-bar', searchBarState)
  }

  public injectInitialData (params: {
    'stats-data-bundle': unknown
    'locale': string | null
    'route-variants': Record<string, string>
    'route-name': string | null
    'initial-domain': string | null
    'analize-selected-background': object | null
  }): void {
    this.statsDataBundle = params['stats-data-bundle']
    this.setLocaleFromLangCode(params.locale)
    this.initialRouteVarians = params['route-variants']
    this.initialRouteName = params['route-name']
    this.initialDomain = params['initial-domain']
    this.analizeSelectedBg = params['analize-selected-background']
  }

  public static newInstance (): UnwrapNestedRefs<AppState> {
    return reactive<AppState>(new AppState())
  }

  public goToLanding (): void {
    this.getChild('search-bar')?.reset()
    this.killAllPageChildStates()
    void this.router.push({ name: 'landing', params: { lang: this.lang } })
    this.buildLandingChild()
  }

  public goToAnalysisWithSearchBarSetup (domain: string): void {
    this.getChild<SearchBarState>('search-bar')?.setSearchText(domain)
    this.goToAnalysis(domain)
  }

  public goToAnalysis (domain: string): void {
    this.killAllPageChildStates()
    void this.router.push({ name: 'analyze', params: { lang: this.lang, domain } })
    this.buildAnalysisChild(domain)
  }

  public goToFeedbackForm (): void {
    this.killAllPageChildStates()
    void this.router.push({ name: 'feedback-form', params: { lang: this.lang } })
    this.addChild('feedback-form', new FeedbackFormState(this))
  }

  private killAllPageChildStates (): void {
    if (this.hasChild('landing')) {
      this.removeChild('landing')
    }

    if (this.hasChild('feedback-form')) {
      this.removeChild('feedback-form')
    }

    const analysisState: AnalysisState | null = this.getChild<AnalysisState>('analysis')

    if (analysisState !== null) {
      analysisState.abortAllRequests()
      analysisState.stopSearchingState()
      this.removeChild('analysis')
    }
  }

  private buildLandingChild (): void {
    this.addChild('landing', new LandingState(this))
  }

  public buildAnalysisChild (domain: string): void {
    const analysisOptions: string[] = [
      'ns-records',
      'soa-record',
      'target-host-records',
      'mx-records',
      'txt-records',
      'spf-record',
      'dmarc-record',
      'caa-records'
    ]

    this.addChild(
      'analysis',
      new AnalysisState(
        this,
        this.getChild('search-bar')!,
        domain,
        this.analizeSelectedBg,
        analysisOptions
      )
    )

    if (!import.meta.env.SSR) {
      this.getChild<AnalysisState>('analysis')?.start()
    }
  }

  public setErrorMessageOnSearchBar (message: string): void {
    this.getChild<SearchBarState>('search-bar')!.setErrorMessageOnSearchBar(message)
  }

  public changeLang (locale: string): void {
    // If not exists on declared locales
    if (LOCALES_TO_LANG_CODES[locale] === undefined) {
      errorLog(`The lang code '${locale}' is not correct`)
      return
    }

    this.lang = locale
    this.changeXAcceptLanguageHeaderInApiRequests()
    this.changeLangCodeOnHTMLRootTag()
    this.changeLocaleOnI18n()
    this.changeLangInXSelectedLangCookie()
    this.pushSameRouteWithCurrentLang()
  }

  public setLocaleFromLangCode (locale: string | null): void {
    if (locale === null || LOCALES_TO_LANG_CODES[locale] === undefined) {
      throw new Error(`The locale '${locale}' is not supponrted`)
    }

    this.lang = locale
    this.changeXAcceptLanguageHeaderInApiRequests()
    this.changeLocaleOnI18n()
  }

  public changeXAcceptLanguageHeaderInApiRequests (): void {
    Axios.defaults.headers.common['X-Accept-Language'] = this.lang
  }

  private changeLangCodeOnHTMLRootTag (): void {
    document.documentElement.setAttribute('lang', (this.lang ?? EN_LOCALE))
  }

  private changeLocaleOnI18n (): void {
    useI18nInstance().locale = this.lang
  }

  private changeLangInXSelectedLangCookie (): void {
    this.setPermanentCookie('X-Selected-Lang', this.lang)
  }

  private setPermanentCookie (name: string, value: string): void {
    const date = new Date()
    date.setFullYear(date.getFullYear() + 1)
    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${date.toUTCString()}; path=/; Secure; SameSite=Lax`
  }

  public evalFirstLoadRoute (): void {
    this.goToRouteByRouteName(this.initialRouteName, this.initialDomain)

    // In SSR mode router never is ready
    /*
     this.router.isReady()
      .then(() => {
        this.goToRouteByRouteName((this.router.currentRoute as any).name.toString(), (this.router.currentRoute as any)?.params?.domain as string | null ?? null)
      })
      .catch((err) => { errorLog(err) })
      */
  }

  private goToRouteByRouteName (routeName: string | null, initialDomain: string | null): void {
    switch (routeName) {
      case 'landing':
        this.goToLanding()
        break
      case 'analyze':
        if (initialDomain === null) {
          throw new Error('The parameter initialDomain are undefined')
        }
        this.goToAnalysisWithSearchBarSetup(initialDomain)
        break
      case 'feedback-form':
        this.goToFeedbackForm()
        break
    }
  }

  private pushSameRouteWithCurrentLang (): void {
    const routeName: string | undefined = (this.router.currentRoute as any).name?.toString()

    if (routeName === undefined) {
      throw new Error('Current route not has name')
    }

    this.router.push(this.buildRouteWithOtherLang(routeName, this.lang))
      .then(() => { this.evaluatePushSameRouteAfterActions((this.router.currentRoute as any)) })
      .catch((error) => { errorLog(error) })
  }

  public buildCanonicalCurrentRoute (): string | undefined {
    return this.buildCurrentRouteWithOtherLangLikeAbsolutePath(this.lang)
  }

  public buildCurrentRouteWithOtherLangLikeAbsolutePath (lang: string): string | undefined {
    const routeName: string | undefined = (this.router.currentRoute as any).name?.toString()

    // In SSR ther router can't start
    if (routeName === undefined) {
      // Only for SSR mode
      return this.buildInitialRouteWithOtherLang(lang)
    }

    return route('root') + this.router.resolve(this.buildRouteWithOtherLang(routeName, lang)).fullPath
  }

  // This is only for emergency cases in SSR render when the router cant't exists
  private buildInitialRouteWithOtherLang (lang: string): string {
    return this.initialRouteVarians[lang]
  }

  private evaluatePushSameRouteAfterActions (route: any): void {
    switch (route.name) {
      case 'analyze':
        this.evaluatePushAnalysisAfterActions()
        break
    }
  }

  private evaluatePushAnalysisAfterActions (): void {
    const domain: string | undefined = (this.router.currentRoute as any).params.domain

    if (domain !== undefined) {
      infoLog('Language changed on analysis, rethrowing analysis')
      this.getChild<SearchBarState>('search-bar')?.onSeachBtnClicked(domain)
    }
  }

  public changeUrlAnchor (id: string): void {
    this.router.isReady().then(() => {
      const routeName: string | undefined = (this.router.currentRoute as any).name?.toString()

      if (routeName === undefined) {
        throw new Error('Current route not has name')
      }

      this.router.push(this.buildRouteWithAnchor(routeName, id))
        .catch((error) => { errorLog(error) })
    }).catch((error) => { errorLog(error) })
  }

  public async getCurrentUrlWithAnchor (id: string): Promise<string> {
    await this.router.isReady()

    const routeName: string | undefined = (this.router.currentRoute as any).name?.toString()

    if (routeName === undefined) {
      throw new Error('Current route not has name')
    }

    return route('root') + this.router.resolve(this.buildRouteWithAnchor(routeName, id)).fullPath
  }

  private buildRouteWithOtherLang (name: string, lang: string): RouteLocationRaw {
    return {
      name,
      params: {
        ...(this.router.currentRoute as any).params,
        lang
      }
    }
  }

  private buildRouteWithAnchor (name: string, anchor: string): RouteLocationRaw {
    return {
      name,
      params: {
        ...(this.router.currentRoute as any).params
      },
      hash: `#${anchor}`
    }
  }

  public getRouter (): Router {
    return this.router
  }
}
