import apiService from '../../services/APIService'
import State from '../State'
import type AnalysisState from '../AnalysisState'
import { ResponseError } from '../../errors/Errors'
import type { Host } from '../../models/Host'
import { useI18nInstance } from '../../services/I18n'
import { CanceledError, type AxiosHeaderValue } from 'axios'
import type { TestResult } from '../../models/Interfaces'
import runTestsTools from './mixins/RunTestTools'
import { infoLog } from '../../helpers/AppHelper'

/**
 * This class extends of RecordState,
 * is for convenience but it really has its peculiarities
 */
export default class DNSHostsState extends State {
  private readonly typeTag: string = 'GENERAL'

  public trace: Host[] | null = null
  public authorityHosts: Host[] | null = null

  public traceServerProcessingTime: string | undefined
  public traceDnsQueryCount: number | undefined

  public authorityHostsAreEquivalentToNSRecordsTest:
  TestResult
  | null
  | undefined

  public allAuthorityHostsHasSameSOATest:
  TestResult
  | null
  | undefined

  public axfrDoesNotResolveOnAnyDNSServerTest:
  TestResult
  | null
  | undefined

  public recommendedNumberOfAuthorityHostsTest:
  TestResult
  | null
  | undefined

  public DNSSECheckTest:
  TestResult
  | null
  | undefined

  constructor (public parent: State, private readonly domain: string) {
    super(parent)
  }

  protected initialDeclaredLoadStepsValue (): number {
    return 6
  }

  public reset (): void {
    super.reset()
    this.trace = null
    this.authorityHosts = null
    this.traceServerProcessingTime = undefined
    this.traceDnsQueryCount = undefined
    this.authorityHostsAreEquivalentToNSRecordsTest = undefined
    this.allAuthorityHostsHasSameSOATest = undefined
    this.axfrDoesNotResolveOnAnyDNSServerTest = undefined
    this.recommendedNumberOfAuthorityHostsTest = undefined
    this.DNSSECheckTest = undefined
  }

  public start (): void {
    apiService
      .fetchTrace(this.domain, this.getParent<AnalysisState>()?.getAbortSignal())
      .then((response) => {
        this.trace = response.data.trace
        this.authorityHosts = response.data['authority-hosts']
        this.traceServerProcessingTime = response.headers['x-processing-time'] ?? '-'
        this.traceDnsQueryCount = parseInt(
          (response.headers['x-dns-query-count'] as AxiosHeaderValue)?.toString() ?? '0'
        )
      })
      .catch((error) => {
        if (error instanceof ResponseError) {
          (this.parent as AnalysisState).onDNSParentsReciveFailed(error.messageError)
        } else if (error instanceof CanceledError) {
          infoLog('DNS trace request cancelled')
        }
      })
      .finally(() => {
        this.finalizeAuthorityHostsLoad()
      })

    super.start()
  }

  protected finalizeAuthorityHostsLoad (): void {
    this.notifyDNSParentsRecive()

    if (this.authorityHosts !== null && this.authorityHosts.length > 0) {
      this.runTests()
    } else {
      this.declaredLoadSteps = 1
    }

    this.notifyLoadStepCompleted()
  }

  protected runTests (): void {
    this.runAuthorityHostsAreEquivalentToNSRecordsTest()
    this.runAllAuthorityHostsHasSameSOATest()
    this.runAxfrDoesNotResolveOnAnyDNSServerTest()
    this.runRecommendedNumberOfAuthorityHostsTest()
    this.runDNSSECheckTest()
  }

  public runAuthorityHostsAreEquivalentToNSRecordsTest (): void {
    this.runTest(
      'authorityHostsAreEquivalentToNSRecordsTest',
      'authority-hosts-are-equivalent-to-ns-records',
      {
        'authority-hosts': this.authorityHosts
      },
      this.typeTag,
      () => {
        if (this.authorityHostsAreEquivalentToNSRecordsTest === undefined || this.authorityHostsAreEquivalentToNSRecordsTest === null) {
          return
        }

        this.getParent<AnalysisState>()?.onAuthorityHostsAreEquivalentToNSRecordsTestRecive(this.authorityHostsAreEquivalentToNSRecordsTest)
      }
    )
  }

  public runAllAuthorityHostsHasSameSOATest (): void {
    this.runTest(
      'allAuthorityHostsHasSameSOATest',
      'all-authority-hosts-has-same-soa',
      {
        mode: 'authority-hosts',
        'authority-hosts': this.authorityHosts
      },
      this.typeTag
    )
  }

  public runAxfrDoesNotResolveOnAnyDNSServerTest (): void {
    this.runTest(
      'axfrDoesNotResolveOnAnyDNSServerTest',
      'axfr-does-not-resolve-on-any-dns-server',
      {
        mode: 'authority-hosts',
        'authority-hosts': this.authorityHosts
      },
      this.typeTag
    )
  }

  protected runRecommendedNumberOfAuthorityHostsTest (): void {
    this.runTest(
      'recommendedNumberOfAuthorityHostsTest',
      'recommended-number-of-authority-hosts',
      {
        'authority-hosts': this.authorityHosts
      },
      this.typeTag
    )
  }

  public runDNSSECheckTest (): void {
    this.runTest(
      'DNSSECheckTest',
      'dnssec-check',
      {},
      this.typeTag
    )
  }

  protected runTest (
    testName: string,
    testServerName: string,
    params: any = {},
    typeTag: string,
    onFinallyCallback: (() => void) | undefined = undefined
  ): void {
    runTestsTools.runTest(
      this,
      testName,
      testServerName,
      params,
      this.domain,
      this.getParent<AnalysisState>()?.getRandomParentHostname() ?? null,
      typeTag,
      this.getParent<AnalysisState>()?.getAbortSignal(),
      () => {
        if (onFinallyCallback !== undefined) {
          onFinallyCallback()
        }
        this.notifyLoadStepCompleted()
      }
    )
  }

  protected finalize (): void {
    this.isReady = true
    this.getParent()?.notifyChildReady()
  }

  private notifyDNSParentsRecive (): void {
    if (this.trace === null) {
      return
    }

    // If some step is null notify failed resolution to parent
    if (this.trace.some((step: Host | null) => step === null)) {
      this.getParent<AnalysisState>()?.onDNSParentsReciveFailed(
        useI18nInstance().t('analysis.no-authoritative-hosts-found')
      )
      return
    }

    this.getParent<AnalysisState>()?.onDNSParentsRecive(this.authorityHosts!)
  }
}
