export class ResponseError extends Error {
  constructor (public messageError: string) {
    super()
  }
}

export class ResponseMultipleErrors extends Error {
  constructor (public errors: Record<string, string[]>) {
    super()
  }
}
