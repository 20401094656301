import { createWebHistory, createMemoryHistory, createRouter, type Router } from 'vue-router'

const routes = [
  { path: '/:lang', component: {}, name: 'landing' },
  { path: '/:lang/analyze/:domain', component: {}, name: 'analyze' },
  { path: '/:lang/feedback-form', component: {}, name: 'feedback-form' }
]

function buidRouter (): Router {
  return createRouter({
    history: import.meta.env.SSR
      ? createMemoryHistory()
      : createWebHistory(),
    routes
  })
}

export { buidRouter }
