import apiService from '../../services/APIService'
import type { AAAARecord, ARecord, MXRecord, SPFRecord, TestResult } from '../../models/Interfaces'
import RecordState from './RecordState'
import type AnalysisState from '../AnalysisState'
import { CanceledError } from 'axios'
import { ResponseError } from '../../errors/Errors'
import { errorLog, infoLog } from '../../helpers/AppHelper'

export default class SPFRecordState extends RecordState {
  public typeTag: string = 'SPF'

  public spfRecord: SPFRecord
  | undefined
  | null

  private aRecords: ARecord[]
  | undefined
  | null

  private aaaaRecords: AAAARecord[]
  | undefined
  | null

  private mxRecords: MXRecord[]
  | undefined
  | null

  public hasOnlyOneSPFAndCorrectFormatTest:
  TestResult
  | null
  | undefined

  public aRecordsCandSendTest:
  TestResult
  | null
  | undefined

  public mxRecordsCandSendTest:
  TestResult
  | null
  | undefined

  public ptrNotRecommendedTest:
  TestResult
  | null
  | undefined

  public allTermIsCorrectlyTest:
  TestResult
  | null
  | undefined

  public isPossibleRunARecordsCanSendTest: boolean = true

  public isPossibleRunMXRecordsCanSendTest: boolean = true

  protected initialDeclaredLoadStepsValue (): number {
    return 6
  }

  public reset (): void {
    super.reset()
    this.spfRecord = undefined
    this.aRecords = undefined
    this.aaaaRecords = undefined
    this.mxRecords = undefined
    this.hasOnlyOneSPFAndCorrectFormatTest = undefined
    this.aRecordsCandSendTest = undefined
    this.mxRecordsCandSendTest = undefined
    this.ptrNotRecommendedTest = undefined
    this.allTermIsCorrectlyTest = undefined
  }

  public start (): void {
    void apiService
      .fetchSPFRecord(
        this.domain,
        this.parentHost.host,
        this.getParent<AnalysisState>()?.getAbortSignal()
      )
      .then((response) => {
        this.spfRecord = response.data
        this.hasRecoveredAnyRecord = this.spfRecord?.record !== null
        this.saveRecordsResponseHeaders(response)
      })
      .catch((error) => {
        this.hasErrorsInRecordFetch = true

        if (error instanceof ResponseError) {
          errorLog(error)
        } else if (error instanceof CanceledError) {
          infoLog(`'${this.typeTag}' records request cancelled`)
        }
      })
      .finally(() => {
        this.runTestsIfHasRecoveredAnyRecord()
      })

    super.start()
  }

  protected runTests (): void {
    this.runHasOnlyOneSPFAndCorrectFormatTest()
    this.runAllTermIsCorrectlyTest()
    this.tryRunARecordsCandSendTest()
    this.tryRunMXRecordsCanSendTest()
    this.runPtrNotRecommendedTest()
  }

  public onARecordsRecive (records: ARecord[] | null): void {
    this.aRecords = records
    this.tryRunARecordsCandSendTest()
  }

  public onAAAARecordsRecive (records: AAAARecord[] | null): void {
    this.aaaaRecords = records
    this.tryRunARecordsCandSendTest()
  }

  public onMXRecordsRecive (records: MXRecord[] | null): void {
    this.mxRecords = records
    this.tryRunMXRecordsCanSendTest()
  }

  private tryRunARecordsCandSendTest (): void {
    if (this.spfRecord === null || this.spfRecord === undefined || !this.hasRecoveredAnyRecord) {
      return
    }

    if (!this.isPossibleRunARecordsCanSendTest) {
      return
    }

    if (this.aRecords === undefined || this.aaaaRecords === undefined) {
      return
    }

    if (this.aRecords === null || this.aaaaRecords === null) {
      this.isPossibleRunARecordsCanSendTest = false
      this.aRecordsCandSendTest = null
      this.notifyLoadStepCompleted()
      return
    }

    this.runARecordsCandSendTest()
  }

  private tryRunMXRecordsCanSendTest (): void {
    if (this.spfRecord === null || this.spfRecord === undefined || !this.hasRecoveredAnyRecord) {
      return
    }

    if (!this.isPossibleRunMXRecordsCanSendTest) {
      return
    }

    if (this.mxRecords === undefined) {
      return
    }

    if (this.mxRecords === null) {
      this.isPossibleRunMXRecordsCanSendTest = false
      this.mxRecordsCandSendTest = null
      this.notifyLoadStepCompleted()
      return
    }

    this.runMXRecordsCandSendTest()
  }

  private runHasOnlyOneSPFAndCorrectFormatTest (): void {
    this.runTest(
      'hasOnlyOneSPFAndCorrectFormatTest',
      'has-only-one-spf-and-correct-format',
      {
        records: [this.spfRecord]
      },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'spfRecord',
          recordsPropertyIsSingleRecord: true
        }
      }
    )
  }

  public runARecordsCandSendTest (): void {
    this.runTest(
      'aRecordsCandSendTest',
      'records-hosts-can-send-emails',
      {
        mode: 'a',
        records: [this.spfRecord],
        'a-records': [...this.aRecords!, ...this.aaaaRecords!]
      },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'spfRecord',
          specificKeyInTestOutputElements: 'records',
          recordsPropertyIsSingleRecord: true
        }
      }
    )
  }

  public runMXRecordsCandSendTest (): void {
    this.runTest(
      'mxRecordsCandSendTest',
      'records-hosts-can-send-emails',
      {
        mode: 'mx',
        records: [this.spfRecord],
        'mx-records': this.mxRecords
      },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'spfRecord',
          specificKeyInTestOutputElements: 'records',
          recordsPropertyIsSingleRecord: true
        }
      }
    )
  }

  public runPtrNotRecommendedTest (): void {
    this.runTest(
      'ptrNotRecommendedTest',
      'ptr-not-recommended',
      {
        records: [this.spfRecord]
      },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'spfRecord',
          recordsPropertyIsSingleRecord: true
        }
      }
    )
  }

  public runAllTermIsCorrectlyTest (): void {
    this.runTest(
      'allTermIsCorrectlyTest',
      'all-term-is-correctly',
      {
        records: [this.spfRecord]
      },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'spfRecord',
          recordsPropertyIsSingleRecord: true
        }
      }
    )
  }

  protected finalize (): void {
    this.isReady = true
    this.getParent()?.notifyChildReady()
  }
}
