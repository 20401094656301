import type { AxiosHeaderValue, AxiosResponse } from 'axios'
import type { Host } from '../../models/Host'
import State from '../State'
import runTestsTools from './mixins/RunTestTools'
import type { RecordTestProperties, TestResult } from '../../models/Interfaces'
import { TEST_NOT_APPLICABLE_STATUS, TEST_SUCCESS_STATUS } from '../../models/constants'
import type AnalysisState from '../AnalysisState'
import { errorLog } from '../../helpers/AppHelper'

interface TestOptions {
  typeTag?: string | undefined
  updateRecordStates?: {
    shouldUpdate?: boolean | undefined
    recordsPropertyName?: string | undefined
    recordsPropertyIsSingleRecord?: boolean | undefined
    specificKeyInTestOutputElements?: string | undefined
    useStricMatchMode?: boolean | undefined
  }
}

export default abstract class RecordState extends State {
  public domain: string
  public typeTag: string

  protected parentHost: Host

  public recordsProcessingTime: string | undefined
  public recordsDnsQueryCount: number | undefined

  public hasRecoveredAnyRecord: boolean = true
  public hasErrorsInRecordFetch: boolean = false
  public nSuccessfulTests: number
  public shouldHideSuccessTests: boolean
  public atLeastOneTestIsOver: boolean

  constructor (parent: State, domain: string) {
    super(parent)
    this.domain = domain
    this.setInitialValues()
  }

  public retrySection (): void {
    this.reset()
    this.start()
  }

  public reset (): void {
    super.reset()
    this.setInitialValues()
  }

  private setInitialValues (): void {
    this.isReady = false
    this.recordsProcessingTime = undefined
    this.recordsDnsQueryCount = undefined
    this.hasRecoveredAnyRecord = true
    this.hasErrorsInRecordFetch = false
    this.nSuccessfulTests = 0
    this.shouldHideSuccessTests = true
    this.atLeastOneTestIsOver = false
  }

  public setParentHost (parentHost: Host): void {
    this.parentHost = parentHost
  }

  protected saveRecordsResponseHeaders (response: AxiosResponse<any, any>): void {
    this.recordsProcessingTime = response.headers['x-processing-time'] ?? '-'
    this.recordsDnsQueryCount = parseInt((response.headers['x-dns-query-count'] as AxiosHeaderValue)?.toString() ?? '0')
  }

  protected runTestsIfHasRecoveredAnyRecord (): void {
    if (this.hasRecoveredAnyRecord) {
      this.runTests()
    } else {
      this.declaredLoadSteps = 1
    }

    this.notifyLoadStepCompleted()
  }

  protected abstract runTests (): void

  protected setTestWithFakeResponseNotAplicable (testName: string, fakeNotApplicableMessage: string): void {
    this[testName] = {
      'test-status': TEST_NOT_APPLICABLE_STATUS,
      'output-elements': [],
      'test-successful-messages': [],
      'test-warning-messages': [fakeNotApplicableMessage],
      'test-info-messages': [],
      'processing-time': '-',
      'dns-query-count': 0

    }

    this.evalAtLeastTestIsOver()
    this.finalizeTestActionsWhenTestExecutedCorrectly(testName, {})
    this.notifyLoadStepCompleted()
  }

  protected runTest (
    testName: string,
    testServerName: string,
    params: any = {},
    options: TestOptions | undefined = undefined
  ): void {
    runTestsTools.runTest(
      this,
      testName,
      testServerName,
      params,
      this.domain,
      this.getParent<AnalysisState>()?.getRandomParentHostname() ?? null,
      options?.typeTag ?? this.typeTag,
      this.getParent<AnalysisState>()?.getAbortSignal(),
      () => {
        this.evalAtLeastTestIsOver()
        if (this[testName] !== undefined && this[testName] !== null) {
          this.finalizeTestActionsWhenTestExecutedCorrectly(testName, options)
        }
        this.notifyLoadStepCompleted()
      }
    )
  }

  private evalAtLeastTestIsOver (): void {
    if (!this.atLeastOneTestIsOver) {
      this.atLeastOneTestIsOver = true
    }
  }

  private finalizeTestActionsWhenTestExecutedCorrectly (testName: string, options: TestOptions | undefined): void {
    if (options?.updateRecordStates?.shouldUpdate === true) {
      try {
        this.updateRecordsStates(testName, options)
      } catch (err) {
        errorLog(err)
      }
    }

    this.updateNSuccessfulTests(testName)
  }

  private updateNSuccessfulTests (testName: string): void {
    if ((this[testName] as TestResult)['test-status'] === TEST_SUCCESS_STATUS) {
      ++this.nSuccessfulTests
    }
  }

  private updateRecordsStates (testName: string, options: TestOptions): void {
    if (options.updateRecordStates?.recordsPropertyName === undefined) {
      throw new Error('Should define recordsPropertyName property in test options')
    }

    if (this[options.updateRecordStates?.recordsPropertyName] === undefined) {
      throw new Error(`Not exists ${options.updateRecordStates?.recordsPropertyName} property in test class`)
    }

    const records: RecordTestProperties[] | RecordTestProperties = this[options.updateRecordStates?.recordsPropertyName] as RecordTestProperties[]
    const outputElementsRecord: Map<string, RecordTestProperties> = this.extractandFormatOutputElementsInTestForUpdateRecordsStates(testName, options)

    // for single record
    if (options.updateRecordStates.recordsPropertyIsSingleRecord === true) {
      this.updateRecord((records as unknown as RecordTestProperties), outputElementsRecord, options)
      return
    }

    // for multiple records
    for (const record of (records)) {
      this.updateRecord(record, outputElementsRecord, options)
    }
  }

  private updateRecord (record: RecordTestProperties, outputElementsRecord: Map<string, RecordTestProperties>, options: TestOptions): void {
    if (record.uuid === undefined) {
      throw new Error('Unexpected record format')
    }

    const outputElement: RecordTestProperties | undefined = outputElementsRecord.get(record.uuid)

    if (outputElement === undefined) {
      if (options.updateRecordStates?.useStricMatchMode ?? true) {
        throw new Error(`Record uuid ${record.uuid} can't match with output element mapped elements`)
      }
      return
    }

    this.updateRecordStatus(record, outputElement)

    this.updateTestFlags(record, outputElement)

    this.updateTestWarningMessages(record, outputElement)
  }

  private updateRecordStatus (record: RecordTestProperties, outputElement: RecordTestProperties): void {
    // Update status
    const outputElementTestStatus: number = outputElement['test-status']!

    // Only update record status with lower
    if (record['test-status'] === undefined) {
      record['test-status'] = TEST_SUCCESS_STATUS
    }

    if (outputElementTestStatus !== TEST_NOT_APPLICABLE_STATUS && outputElementTestStatus < record['test-status']) {
      record['test-status'] = outputElementTestStatus
    }
  }

  private updateTestFlags (record: RecordTestProperties, outputElement: RecordTestProperties): void {
    if (record['test-flags'] === undefined) {
      record['test-flags'] = {}
    }

    // Update with outputElement test flags priority
    record['test-flags'] = {
      ...record['test-flags'],
      ...outputElement['test-flags']!
    }
  }

  private updateTestWarningMessages (record: RecordTestProperties, outputElement: RecordTestProperties): void {
    if (record['test-warning-messages'] === undefined) {
      record['test-warning-messages'] = []
    }

    for (const message of outputElement['test-warning-messages']!) {
      if (!record['test-warning-messages']?.includes(message)) {
        record['test-warning-messages'].push(message)
      }
    }
  }

  private extractandFormatOutputElementsInTestForUpdateRecordsStates (testName: string, options: TestOptions): Map<string, RecordTestProperties> {
    let outputElements: object[] = (this[testName] as TestResult)['output-elements']

    if (options.updateRecordStates?.specificKeyInTestOutputElements !== undefined) {
      const specificOutputElements: object[] | undefined = outputElements[options.updateRecordStates?.specificKeyInTestOutputElements]

      if (specificOutputElements !== undefined) {
        outputElements = specificOutputElements
      }
    }

    if (!this.allOutputElementsHasExpectedKeys(outputElements)) {
      throw new Error('The output elements has unexpected format')
    }

    const map = new Map<string, RecordTestProperties>()

    for (const element of (outputElements as RecordTestProperties[])) {
      map.set(element.uuid!, element)
    }

    return map
  }

  private allOutputElementsHasExpectedKeys (outputElements: object[]): boolean {
    return outputElements.every(
      (element: any) =>
        typeof element.uuid === 'string' &&
        typeof element['test-status'] === 'number' &&
        typeof element['test-flags'] === 'object' &&
        Array.isArray(element['test-warning-messages'])
    )
  }

  public setShouldHideSuccessTests (value: boolean): void {
    this.shouldHideSuccessTests = value
  }

  public toggleShouldHideSuccessTests (): void {
    this.shouldHideSuccessTests = !this.shouldHideSuccessTests
  }
}
