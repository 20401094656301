import apiService from '../../services/APIService'
import RecordState from './RecordState'
import type { AAAARecord, ARecord, CNAMERecord, TestResult } from '../../models/Interfaces'
import type { HasRecordsHasRecommendedTTLTest } from './mixins/HasRecordsHasRecommendedTTLTest'
import type AnalysisState from '../AnalysisState'
import { CanceledError, type AxiosHeaderValue, type AxiosResponse } from 'axios'
import { ResponseError } from '../../errors/Errors'
import { errorLog, infoLog } from '../../helpers/AppHelper'

export default class TargetHostRecordsState extends RecordState implements HasRecordsHasRecommendedTTLTest {
  public records: Array<ARecord | AAAARecord | CNAMERecord> | undefined

  public recordsHasRecommendedTTLTest:
  TestResult
  | null
  | undefined

  protected initialDeclaredLoadStepsValue (): number {
    return 2
  }

  public reset (): void {
    super.reset()
    this.records = undefined
    this.recordsHasRecommendedTTLTest = undefined
  }

  public start (): void {
    super.start()
    void this.loadData()
  }

  private async loadData (): Promise<void> {
    const abortSignal: AbortSignal | undefined = this.getParent<AnalysisState>()?.getAbortSignal()

    let results: any[] = []

    try {
      results = await Promise.all([
        apiService.fetchRecords(
          this.domain,
          this.parentHost.host,
          'A',
          abortSignal
        ),
        apiService.fetchRecords(
          this.domain,
          this.parentHost.host,
          'AAAA',
          abortSignal
        ),
        apiService.fetchRecords(
            `www.${this.domain.trim()}`,
            this.parentHost.host,
            'CNAME',
            abortSignal
        )
      ])
    } catch (error) {
      if (error instanceof ResponseError) {
        errorLog(error)
      } else if (error instanceof CanceledError) {
        infoLog('All target hosts records requests cancelled')
      }
    }

    this.notifyRecordsReciveToAnalysisParent(results)

    this.processResults(this.filterFailedResults(results))

    this.runTestsIfHasRecoveredAnyRecord()
  }

  private notifyRecordsReciveToAnalysisParent (results: any[]): void {
    const parent: AnalysisState | null = this.getParent<AnalysisState>()

    // If resutls[0] not exist or is Error instance send null in param
    parent?.onARecordsRecive(results[0] === undefined || results[0] instanceof Error ? null : (results[0].data as ARecord[]))
    parent?.onAAAARecordsRecive(results[1] === undefined || results[1] instanceof Error ? null : (results[1].data as AAAARecord[]))
  }

  private filterFailedResults (results: any[]): Array<AxiosResponse<any, any>> {
    return results.filter((result) => {
      if (result instanceof ResponseError) {
        errorLog(result)
        return false
      }

      if (result instanceof CanceledError) {
        infoLog('Target host (undefined record type) records request cancelled')
        return false
      }

      return true
    })
  }

  private processResults (
    results: Array<AxiosResponse<any, any>>
  ): void {
    let records: Array<ARecord | AAAARecord | CNAMERecord> = []

    let rawProcessingTime: string
    let maxProcessingTime: number = 0
    let auxProcessingTime: number

    let recordsDnsQueryCountAccumulator: number = 0

    if (results.length === 0) {
      this.hasErrorsInRecordFetch = true
      return
    }

    for (const result of results) {
      records = records.concat(...(result.data as Array<ARecord | AAAARecord | CNAMERecord>))

      rawProcessingTime = result.headers['x-processing-time']

      auxProcessingTime = parseInt(rawProcessingTime.substring(0, rawProcessingTime.length - 2))

      if (auxProcessingTime > maxProcessingTime) {
        maxProcessingTime = auxProcessingTime
      }

      recordsDnsQueryCountAccumulator += parseInt((result.headers['x-dns-query-count'] as AxiosHeaderValue)?.toString() ?? '0')
    }

    this.hasRecoveredAnyRecord = records.length > 0
    this.records = records
    this.recordsProcessingTime = maxProcessingTime + 'ms'
    this.recordsDnsQueryCount = recordsDnsQueryCountAccumulator
  }

  protected runTests (): void {
    this.runRecordsHasRecommendedTTLTest()
  }

  public runRecordsHasRecommendedTTLTest (): void {
    this.runTest(
      'recordsHasRecommendedTTLTest',
      'records-has-recommended-ttl-range',
      { records: this.records },
      {
        typeTag: 'TARGET_HOST',
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'records'
        }
      }
    )
  }

  protected finalize (): void {
    this.isReady = true
    this.getParent()?.notifyChildReady()
  }
}
