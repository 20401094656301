import { createSSRApp, h, type App as Application } from 'vue'

import { VueReCaptcha } from 'vue-recaptcha-v3'

import { ZiggyVue } from 'ziggy-js'
import { Ziggy } from '../../js/ziggy'

import { createI18n, type I18n } from 'vue-i18n'
import es from '../lang/es'
import en from '../lang/en'
import fr from '../lang/fr'
import de from '../lang/de'
import pt from '../lang/pt'

import { setI18nInstance } from '../services/I18n'

import { AppState } from '../states/AppState'

import { DE_LOCALE, EN_LOCALE, ES_LOCALE, FR_LOCALE, PT_LOCALE } from '../models/constants'
import { directive as typpy, setDefaultProps } from 'vue-tippy'

import ClientOnly from '../components/commons/ClientOnly.vue'

function configtippy (): void {
  setDefaultProps({
    theme: 'custom-tippy',
    animation: 'shift-toward'
  })
}

function configI18n (locale): I18n {
  // Config Translations
  const i18n: I18n = createI18n({
    legacy: true,
    locale,
    fallbackLocale: EN_LOCALE,
    warnHtmlInMessage: 'off',
    messages: {
      [EN_LOCALE]: en,
      [ES_LOCALE]: es,
      [FR_LOCALE]: fr,
      [DE_LOCALE]: de,
      [PT_LOCALE]: pt
    }
  })

  setI18nInstance(i18n.global)

  return i18n
}

function extractStatsDataBundle (props: any): any {
  return props['stats-data-bundle'] ?? {}
}

function extractAnalizeSelectedBg (props: any): any {
  return props['analize-selected-background'] ?? null
}

// Select lang code from Node env or browser env
function extractInitialLocaleFromEnvironment (props: any): string {
  if (import.meta.env.SSR && props.lang !== undefined) {
    return props.lang
  }

  return document.documentElement.getAttribute('lang') ?? EN_LOCALE
}

function extractInitialRouteVariants (props: any): Record<string, string> {
  return props['route-variants'] ?? {}
}

function extractInitialRouteName (props: any): string | null {
  return props['route-name'] ?? null
}

function extractInitiaDomain (props: any): string | null {
  return props['initial-domain'] ?? null
}

const recaptchaOptions = {
  siteKey: import.meta.env.VITE_RECAPTCHA_SITE_ID,
  loaderOptions: {
    autoHideBadge: true
  }
}

const createBaseApp = (App, props): [Application, AppState] => {
  const locale: string = extractInitialLocaleFromEnvironment(props.initialPage.props)

  configtippy()

  const i18n: I18n = configI18n(locale)

  const state = AppState.newInstance()

  state.injectInitialData({
    'stats-data-bundle': extractStatsDataBundle(props.initialPage.props),
    locale,
    'route-variants': extractInitialRouteVariants(props.initialPage.props),
    'route-name': extractInitialRouteName(props.initialPage.props),
    'initial-domain': extractInitiaDomain(props.initialPage.props),
    'analize-selected-background': extractAnalizeSelectedBg(props.initialPage.props)
  })

  state.start()

  state.evalFirstLoadRoute()

  const vueApp = createSSRApp({ render: () => h(App, props) }) // eslint-disable-line
    .component('client-only', ClientOnly) // eslint-disable-line
    .directive('tippy', typpy)
    .use(VueReCaptcha, recaptchaOptions)
    .use(i18n)
    .use(state.getRouter())
    .use(ZiggyVue, Ziggy)
    .provide('app-state', state)

  return [vueApp, state as unknown as AppState]
}

export { createBaseApp }
