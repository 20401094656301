import data from './email-providers.json'
import mailgun from '../assets/images/mail_senders/mailgun.svg'
import gmail from '../assets/images/mail_senders/gmail.svg'
import brevo from '../assets/images/mail_senders/brevo.svg'
import mandrillapp from '../assets/images/mail_senders/mandrillapp.svg'
import aws from '../assets/images/mail_senders/aws.svg'
import mail from '../assets/images/mail_senders/mail.svg'
import proton from '../assets/images/mail_senders/proton.svg'
import microsoft from '../assets/images/mail_senders/microsoft.svg'
import apple from '../assets/images/mail_senders/apple.svg'
import sap from '../assets/images/mail_senders/sap.svg'
import raiola from '../assets/images/mail_senders/raiola.svg'
import mailchimp from '../assets/images/mail_senders/mailchimp.svg'
import sendgrid from '../assets/images/mail_senders/sendgrid.svg'
import salesforce from '../assets/images/mail_senders/salesforce.svg'

interface Provider {
  icon: string
  spfs: string[]
}

const mappedProviders = {}

function boot (): void {
  const providers: Provider[] = Object.values(data)

  for (const provider of providers) {
    for (const spf of provider.spfs) {
      mappedProviders[spf] = provider.icon
    }
  }
}

function getIconFromSPF (spf: string): string {
  switch (mappedProviders[spf]) {
    case 'raiola':
      return raiola
    case 'brevo':
      return brevo
    case 'mandrillapp':
      return mandrillapp
    case 'mailgun':
      return mailgun
    case 'mailchimp':
      return mailchimp
    case 'amazon':
      return aws
    case 'proton':
      return proton
    case 'gmail':
      return gmail
    case 'microsoft':
      return microsoft
    case 'apple':
      return apple
    case 'sap':
      return sap
    case 'salesforce':
      return salesforce
    case 'sendgrid':
      return sendgrid
    default:
      return mail
  }
}

export default {
  boot, getIconFromSPF
}
