import type SearchBarState from './SearchBarState'
import State from './State'
import DNSHostsState from './AnalysisState/DNSHostsState'
import NSRecordsState from './AnalysisState/NSRecordsState'
import TargetHostRecordsState from './AnalysisState/TargetHostRecordsState'
import MXRecordsState from './AnalysisState/MXRecordsState'
import TXTRecordsState from './AnalysisState/TXTRecordsState'
import RecordState from './AnalysisState/RecordState'
import type { AppState } from './AppState'
import type { Host } from '../models/Host'
import SPFRecordState from './AnalysisState/SPFRecordState'
import type { AAAARecord, ARecord, MXRecord, NSRecord, TestResult } from '../models/Interfaces'
import DMARCRecordState from './AnalysisState/DMARCRecordState'
import SOARecordState from './AnalysisState/SOARecordState'
import { TEST_SUCCESS_STATUS } from '../models/constants'
import { errorLog, infoLog } from '../helpers/AppHelper'
import CAARecordState from './AnalysisState/CAARecordsState'

type RecordStateClass = (new(parent: State, domain: string) => RecordState)

export default class AnalysisState extends State {
  private readonly sectionStates: Map<string, RecordStateClass> = new Map<string, RecordStateClass>([
    ['ns-records', NSRecordsState],
    ['soa-record', SOARecordState],
    ['target-host-records', TargetHostRecordsState],
    ['mx-records', MXRecordsState],
    ['txt-records', TXTRecordsState],
    ['spf-record', SPFRecordState],
    ['dmarc-record', DMARCRecordState],
    ['caa-records', CAARecordState]
  ])

  private authorityHosts: Host[]

  public authorityHostsCorrectlyConfigured: Host[] | undefined

  public randomParent: Host | undefined

  public viewAllDetailsFlag: boolean = false

  private readonly requestsAbortController: AbortController

  constructor (
    parent: State,
    private readonly searchBarState: SearchBarState,
    public domain: string,
    public readonly analizeSelectedBg: object | null,
    private readonly analysisOptions: string[]
  ) {
    super(parent)

    this.autoStartChildren = false

    this.requestsAbortController = new AbortController()

    this.addChild('tree-trace-hosts', new DNSHostsState(this, this.domain))

    this.instanceChilden()
  }

  private instanceChilden (): void {
    let SectionState: RecordStateClass | undefined

    for (const option of this.analysisOptions) {
      if (((SectionState = this.sectionStates.get(option)) !== undefined)) {
        this.addChild(option, new SectionState(this, this.domain))
      }
    }
  }

  public start (): void {
    this.searchBarState.isSearching = true
    super.start()
    this.getChild('tree-trace-hosts')?.start()
  }

  protected onAllChildrenAreReady (): void {
    this.searchBarState.isSearching = false
    infoLog('All analysis completed')
  }

  public stopSearchingState (): void {
    this.searchBarState.isSearching = false
  }

  public onDNSParentsRecive (authorityHosts: Host[]): void {
    this.authorityHosts = authorityHosts

    this.authorityHostsCorrectlyConfigured = this.authorityHosts.filter((host: Host) => host.status === TEST_SUCCESS_STATUS)

    // This is for bad case to interrupt the analysis
    if (this.authorityHostsCorrectlyConfigured.length === 0) {
      return
    }

    // Extract random authority hosts corretly configured
    this.randomParent =
            this.authorityHostsCorrectlyConfigured[Math.floor(Math.random() * this.authorityHostsCorrectlyConfigured.length)]

    this.startRecordChildrenStates()
  }

  public onDNSParentsReciveFailed (message: string): void {
    this.getParent<AppState>()?.setErrorMessageOnSearchBar(message)
  }

  public onARecordsRecive (records: ARecord[] | null): void {
    this.getChild<SPFRecordState>('spf-record')?.onARecordsRecive(records)
  }

  public onAAAARecordsRecive (records: AAAARecord[] | null): void {
    this.getChild<SPFRecordState>('spf-record')?.onAAAARecordsRecive(records)
  }

  public onMXRecordsRecive (records: MXRecord[] | null): void {
    this.getChild<SPFRecordState>('spf-record')?.onMXRecordsRecive(records)
  }

  public onAuthorityHostsAreEquivalentToNSRecordsTestRecive (authorityHostsAreEquivalentToNSRecordsTest: TestResult): void {
    let extraNS: string[]

    try {
      extraNS = this.findExtraNSValuesThatNotExistentAsDNSServersInTrace(authorityHostsAreEquivalentToNSRecordsTest)
    } catch (error) {
      errorLog(error)
      return
    }

    if (extraNS.length === 0) {
      infoLog('There are no extra NS records that are not present in the authority hosts of the trace')
      return
    }

    this.getChild<NSRecordsState>('ns-records')?.onExtraNsRecordsReciveForDNSExtraTests(extraNS)
  }

  private findExtraNSValuesThatNotExistentAsDNSServersInTrace (authorityHostsAreEquivalentToNSRecordsTest: TestResult): string[] {
    const nsRecords: NSRecord[] | null = ((authorityHostsAreEquivalentToNSRecordsTest['output-elements'] as unknown) as { ns: NSRecord[] | undefined })?.ns ?? null

    if (nsRecords === null) {
      throw new Error("The key 'ns' on 'output-elements' on authorityHostsAreEquivalentToNSRecordsTest not exists, this is an unexpected error")
    }

    return nsRecords.filter((record: NSRecord) => {
      const pointsToAuthorityHostCorrectlyFlag: boolean | null = record['test-flags']!['ns-record-points-to-authority-hosts-correctly'] ?? null

      if (pointsToAuthorityHostCorrectlyFlag === null) {
        throw new Error("The key flag 'ns-record-points-to-authority-hosts-correctly' on 'test-flags' on some 'ns' on 'output-elements' on authorityHostsAreEquivalentToNSRecordsTest not exists, this is an unexpected error")
      }

      return !pointsToAuthorityHostCorrectlyFlag
    })
      .map((record: NSRecord) => record.value)
  }

  private startRecordChildrenStates (): void {
    this.childrenStates.forEach((state: State, key: string) => {
      if (state instanceof RecordState && this.randomParent !== undefined) {
        state.setParentHost(this.randomParent)
        state.start()
      }
    })
  }

  public toggleViewAllDetails (): void {
    this.viewAllDetailsFlag = !this.viewAllDetailsFlag
  }

  public getAuthorityHosts (): Host[] {
    return this.authorityHosts
  }

  public getRandomParentHostname (): string | null {
    return this.randomParent?.host ?? null
  }

  public abortAllRequests (): void {
    this.requestsAbortController.abort()
  }

  public getAbortSignal (): AbortSignal {
    return this.requestsAbortController.signal
  }
}
