// A global singletone resource to use in any site
interface Translator {
  locale: string
  t: (key: string, params?: unknown) => string
}

let i18n: Translator

function useI18nInstance (): Translator {
  return i18n
}

function setI18nInstance (instance: any): void {
  i18n = instance
}

export { setI18nInstance, useI18nInstance }
