export const TEST_NOT_APPLICABLE_STATUS: number = 5

export const TEST_SUCCESS_STATUS: number = 1

export const TEST_WARNING_STATUS: number = 0

export const TEST_FAIL_STATUS: number = -1

export const ES_LOCALE = 'es'
export const EN_LOCALE = 'en'
export const FR_LOCALE = 'fr'
export const DE_LOCALE = 'de'
export const PT_LOCALE = 'pt'

export const ES_LANG_CODE = 'es-ES'
export const EN_LANG_CODE = 'en-GB'
export const FR_LANG_CODE = 'fr-FR'
export const DE_LANG_CODE = 'de-DE'
export const PT_LANG_CODE = 'pt-PT'

export const LANG_CODES_TO_LOCALES = {
  [ES_LANG_CODE]: ES_LOCALE,
  [EN_LANG_CODE]: EN_LOCALE,
  [FR_LANG_CODE]: FR_LOCALE,
  [DE_LANG_CODE]: DE_LOCALE,
  [PT_LANG_CODE]: PT_LOCALE
}

export const LOCALES_TO_LANG_CODES = {
  [ES_LOCALE]: ES_LANG_CODE,
  [EN_LOCALE]: EN_LANG_CODE,
  [FR_LOCALE]: FR_LANG_CODE,
  [DE_LOCALE]: DE_LANG_CODE,
  [PT_LOCALE]: PT_LANG_CODE
}
