import { type AppState } from './AppState'
import State from './State'
import api from '../services/APIService'
import { ResponseError } from '../errors/Errors'

export default class SearchBarState extends State {
  public initialDomain: string | null = null

  public isSearching: boolean

  public errorMessage: string | undefined

  public reset (): void {
    super.reset()
    this.isSearching = false
    this.errorMessage = undefined
  }

  public setSearchText (domain: string): void {
    this.initialDomain = domain
  }

  public onSeachBtnClicked (domain: string): void {
    this.isSearching = true
    this.errorMessage = undefined

    api.checkDomain(domain)
      .then((response) => {
        if (!(response.data['is-valid'] as boolean)) {
          this.setErrorMessageOnSearchBar((response.data.message as string))
          return
        }

        const sanitisedDomain: string = response.data['sanitised-domain']

        this.getParent<AppState>()?.goToAnalysis(sanitisedDomain)
      })
      .catch((error) => {
        if (error instanceof ResponseError) {
          this.setErrorMessageOnSearchBar(error.messageError)
        }
        this.isSearching = false
      })
  }

  public setErrorMessageOnSearchBar (message: string): void {
    this.isSearching = false
    this.errorMessage = message
  }
}
