import axios, { AxiosError, CanceledError, type AxiosResponse } from 'axios'
import { ResponseError, ResponseMultipleErrors } from '../errors/Errors'
import { useI18nInstance } from './I18n'
import { route } from '../helpers/RouteHelper'

// This request is not cancellable
async function checkDomain (host: string): Promise<AxiosResponse<any, any>> {
  let response: AxiosResponse<any, any>

  try {
    response = await axios.get(
      route('api.validate-domain', { stage: 'v1' }),
      {
        params: {
          host
        },
        timeout: 4000
      }
    )
  } catch (error: any | AxiosError) {
    let message: string = useI18nInstance().t('unexpected-error')

    if (error instanceof AxiosError &&
      error.response?.status != null &&
      error.response?.status >= 400 &&
      error.response?.status < 500) {
      message = error.response?.data?.message
    }

    throw new ResponseError(message)
  }

  return response
}

async function fetchTrace (host: string, abortSignal: AbortSignal | undefined): Promise<AxiosResponse<any, any>> {
  let response: AxiosResponse<any, any>

  try {
    response = await axios.get(
      route('api.get-dns-hosts', { stage: 'v1' }),
      {
        signal: abortSignal,
        params: {
          host,
          'wants-trace': 1
        },
        timeout: 30000
      }
    )
  } catch (error: any | AxiosError) {
    if (error instanceof CanceledError) {
      throw error
    }

    let message: string = useI18nInstance().t('unexpected-error')

    if (error instanceof AxiosError &&
      error.response?.status != null &&
      error.response?.status >= 400 &&
      error.response?.status < 500) {
      message = error.response?.data?.message
    }

    throw new ResponseError(message)
  }

  return response
}

async function fetchRecords (
  host: string,
  nameServer: string,
  type: string,
  abortSignal: AbortSignal | undefined
): Promise<AxiosResponse<any, any>> {
  let response: AxiosResponse<any, any>

  try {
    response = await axios.get(
      route('api.get-dns-records', { stage: 'v1' }),
      {
        signal: abortSignal,
        params: {
          host,
          'name-server': nameServer,
          type
        },
        timeout: 30000
      }
    )
  } catch (error) {
    if (error instanceof CanceledError) {
      throw error
    }
    throw new ResponseError(useI18nInstance().t('unexpected-error'))
  }

  return response
}

async function fetchSPFRecord (host: string, nameServer: string, abortSignal: AbortSignal | undefined): Promise<AxiosResponse<any, any>> {
  let response: AxiosResponse<any, any>

  try {
    response = await axios.get(
      route('api.get-spf-record', { stage: 'v1' }),
      {
        signal: abortSignal,
        params: {
          host,
          'name-server': nameServer
        },
        timeout: 20000
      }
    )
  } catch (error) {
    if (error instanceof CanceledError) {
      throw error
    }
    throw new ResponseError(useI18nInstance().t('unexpected-error'))
  }

  return response
}

async function fetchDMARCRecord (host: string, nameServer: string, abortSignal: AbortSignal | undefined): Promise<AxiosResponse<any, any>> {
  let response: AxiosResponse<any, any>

  try {
    response = await axios.get(
      route('api.get-dmarc-record', { stage: 'v1' }),
      {
        signal: abortSignal,
        params: {
          host,
          'name-server': nameServer
        },
        timeout: 20000
      }
    )
  } catch (error) {
    if (error instanceof CanceledError) {
      throw error
    }
    throw new ResponseError(useI18nInstance().t('unexpected-error'))
  }

  return response
}

async function fetchTest (
  host: string,
  targetHostname: string | null,
  type: string,
  testName: string,
  testParams: any,
  abortSignal: AbortSignal | undefined
): Promise<AxiosResponse<any, any>> {
  let response: AxiosResponse<any, any>
  try {
    response = await axios.post(
      route('api.run-test', { stage: 'v1' }),
      {
        host,
        'target-hostname': targetHostname,
        type,
        'test-name': testName,
        'test-params': testParams
      },
      {
        signal: abortSignal,
        timeout: 40000
      }
    )
  } catch (error) {
    if (error instanceof CanceledError) {
      throw error
    }
    throw new ResponseError(useI18nInstance().t('unexpected-error'))
  }

  return response
}

async function sendFeedback (email: string | undefined, message: string | undefined, recaptchaToken: string | undefined): Promise<AxiosResponse<any, any>> {
  let response: AxiosResponse<any, any>

  try {
    response = await axios.post(
      route('send-feedback'),
      {
        email,
        message,
        'recaptcha-token': recaptchaToken
      },
      {
        timeout: 10000
      }
    )
  } catch (error: any | AxiosError) {
    let errors: Record<string, string[]> = { message: [useI18nInstance().t('unexpected-error')] }

    if (error instanceof AxiosError &&
      error.response?.status != null &&
      (error.response?.status < 400 ||
      error.response?.status >= 500)) {
      throw new ResponseMultipleErrors(errors)
    }

    if (error.response?.data?.errors !== undefined) {
      errors = error.response?.data?.errors
    } else if (error.response?.data?.message !== undefined) {
      errors = { message: [error.response?.data?.message] }
    }

    throw new ResponseMultipleErrors(errors)
  }

  return response
}

export default {
  route,
  checkDomain,
  fetchTrace,
  fetchRecords,
  fetchSPFRecord,
  fetchDMARCRecord,
  fetchTest,
  sendFeedback
}
