import apiService from '../../services/APIService'
import type { DMARCRecord, TestResult } from '../../models/Interfaces'
import RecordState from './RecordState'
import type AnalysisState from '../AnalysisState'
import { CanceledError } from 'axios'
import { ResponseError } from '../../errors/Errors'
import { errorLog, infoLog } from '../../helpers/AppHelper'

export default class DMARCRecordState extends RecordState {
  public typeTag: string = 'DMARC'

  public dmarcRecord: DMARCRecord
  | undefined
  | null

  public hasOnlyOneDMARCAndCorrectFormatTest:
  TestResult
  | null
  | undefined

  public policyTermTest:
  TestResult
  | null
  | undefined

  public subdomainPolicyTermTest:
  TestResult
  | null
  | undefined

  public pctTermTest:
  TestResult
  | null
  | undefined

  public ruaAndRufTermsTest:
  TestResult
  | null
  | undefined

  public foTermTest:
  TestResult
  | null
  | undefined

  public adkimTermTest:
  TestResult
  | null
  | undefined

  public aspfTermTest:
  TestResult
  | null
  | undefined

  protected initialDeclaredLoadStepsValue (): number {
    return 9
  }

  public reset (): void {
    super.reset()
    this.dmarcRecord = undefined
    this.hasOnlyOneDMARCAndCorrectFormatTest = undefined
    this.policyTermTest = undefined
    this.subdomainPolicyTermTest = undefined
    this.pctTermTest = undefined
    this.ruaAndRufTermsTest = undefined
    this.foTermTest = undefined
    this.adkimTermTest = undefined
    this.aspfTermTest = undefined
  }

  public start (): void {
    void apiService
      .fetchDMARCRecord(
        this.domain,
        this.parentHost.host,
        this.getParent<AnalysisState>()?.getAbortSignal()
      )
      .then((response) => {
        this.dmarcRecord = response.data
        this.hasRecoveredAnyRecord = this.dmarcRecord?.record !== null
        this.saveRecordsResponseHeaders(response)
      })
      .catch((error) => {
        this.hasErrorsInRecordFetch = true

        if (error instanceof ResponseError) {
          errorLog(error)
        } else if (error instanceof CanceledError) {
          infoLog(`'${this.typeTag}' records request cancelled`)
        }
      })
      .finally(() => {
        this.runTestsIfHasRecoveredAnyRecord()
      })

    super.start()
  }

  protected runTests (): void {
    this.runHasOnlyOneDMARCAndCorrectFormatTest()
    this.runPolicyTermTest()
    this.runSubdomainPolicyTermTest()
    this.runPctTermTest()
    this.runRuaAndRufTermsTest()
    this.runFoTermTest()
    this.runAdkimTermTest()
    this.runAspfTermTest()
  }

  public runHasOnlyOneDMARCAndCorrectFormatTest (): void {
    this.runTest(
      'hasOnlyOneDMARCAndCorrectFormatTest',
      'has-only-one-dmarc-and-correct-format',
      {
        records: [this.dmarcRecord]
      },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'dmarcRecord',
          recordsPropertyIsSingleRecord: true
        }
      }
    )
  }

  public runPolicyTermTest (): void {
    this.runTest(
      'policyTermTest',
      'policy-term',
      {
        records: [this.dmarcRecord]
      },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'dmarcRecord',
          recordsPropertyIsSingleRecord: true
        }
      }
    )
  }

  public runSubdomainPolicyTermTest (): void {
    this.runTest(
      'subdomainPolicyTermTest',
      'subdomain-policy-term',
      {
        records: [this.dmarcRecord]
      },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'dmarcRecord',
          recordsPropertyIsSingleRecord: true
        }
      }
    )
  }

  public runPctTermTest (): void {
    this.runTest(
      'pctTermTest',
      'pct-term',
      {
        records: [this.dmarcRecord]
      },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'dmarcRecord',
          recordsPropertyIsSingleRecord: true
        }
      }
    )
  }

  public runRuaAndRufTermsTest (): void {
    this.runTest(
      'ruaAndRufTermsTest',
      'rua-and-ruf-terms',
      {
        records: [this.dmarcRecord]
      },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'dmarcRecord',
          recordsPropertyIsSingleRecord: true
        }
      }
    )
  }

  public runFoTermTest (): void {
    this.runTest(
      'foTermTest',
      'fo-term',
      {
        records: [this.dmarcRecord]
      },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'dmarcRecord',
          recordsPropertyIsSingleRecord: true
        }
      }
    )
  }

  public runAdkimTermTest (): void {
    this.runTest(
      'adkimTermTest',
      'adkim-term',
      {
        records: [this.dmarcRecord]
      },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'dmarcRecord',
          recordsPropertyIsSingleRecord: true
        }
      }
    )
  }

  public runAspfTermTest (): void {
    this.runTest(
      'aspfTermTest',
      'aspf-term',
      {
        records: [this.dmarcRecord]
      },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'dmarcRecord',
          recordsPropertyIsSingleRecord: true
        }
      }
    )
  }

  protected finalize (): void {
    this.isReady = true
    this.getParent()?.notifyChildReady()
  }
}
