import type { AppState } from './AppState'

export default abstract class State {
  protected autoStartChildren: boolean = true

  public isReady: boolean = true

  protected parent: State | null
  protected childrenStates: Map<string, State>
  protected appState: AppState | null

  protected declaredLoadSteps: number
  private loadStepsCompleted: number

  constructor (
    parent: State | null = null
  ) {
    this.parent = parent
    this.childrenStates = new Map<string, State>()
    this.resetLoadStepsSystem()
  }

  public reset (): void {
    this.resetLoadStepsSystem()
    this.childrenStates.forEach((state: State) => {
      state.reset()
    })
  }

  private resetLoadStepsSystem (): void {
    this.declaredLoadSteps = this.initialDeclaredLoadStepsValue()
    this.loadStepsCompleted = 0
  }

  public start (): void {
    if (this.autoStartChildren) {
      for (const state of this.childrenStates.values()) {
        state.start()
      }
    }
  }

  protected addChild (key: string, state: State): void {
    this.childrenStates.set(key, state)
  }

  public getChild<T extends State> (key: string): T | null {
    return (this.childrenStates.get(key) ?? null) as T | null
  }

  public hasChild (key: string): boolean {
    return this.childrenStates.has(key)
  }

  public getParent<T extends State> (): T | null {
    return this.parent as T | null
  }

  protected removeChild (key: string): boolean {
    return this.childrenStates.delete(key)
  }

  public notifyChildReady (): void {
    if (this.checkIfAllChildrenAreReady()) {
      this.onAllChildrenAreReady()
    }
  }

  protected onAllChildrenAreReady (): void {}

  protected checkIfAllChildrenAreReady (): boolean {
    for (const child of this.childrenStates.values()) {
      if (!child.isReady) {
        return false
      }
    }

    return true
  }

  public notifyLoadStepCompleted (): void {
    this.loadStepsCompleted++

    if (this.loadStepsCompleted >= this.declaredLoadSteps) {
      this.finalize()
    }
  }

  protected finalize (): void {}

  protected initialDeclaredLoadStepsValue (): number {
    return 0
  }
}
