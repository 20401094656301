export default {
  'unexpected-error': 'Unexpected error',
  'copied-to-clipboard': 'Copied to clipboard',
  header: {
    title: 'Check and analyse the DNS records of your domain',
    'logo-alt': 'IntoDNS',
    'search-button-aria-label': 'Analyse the domain',
    analize: 'Analyse',
    loading: 'Loading',
    'search-bar-aria-label': 'Domain search bar',
    'change-language': 'Change language',
    'select-language': 'Select language \'{lang}\'',
    'selected-language-flag': 'Selected language flag of \'{lang}\'',
    'alternative-language-flag': 'Alternative language flag of \'{lang}\''
  },
  footer: {
    title: 'Need Hosting for your domain?',
    description:
            'At Raiola Networks we offer hosting for your domain, and in terms of DNS servers, we are well covered, as we have redundant and distributed DNS servers around the world for the services hosted with us',
    'discover-it': 'Discover it!',
    'visit-our-blog': 'Visit our blog!',
    'raiola-server-alt': 'Raiola server',
    'raiola-team-alt': 'Raiola Networks Systems Team',
    'api-docs': 'API Documentation',
    'go-to-docs-api-version': 'Go to V{version}'
  },
  landing: {
    domains: 'Domains',
    'dns-records': 'DNS Records',
    'a-records': 'A Records',
    'ns-records': 'NS Records',
    'mx-records': 'MX Records',
    'head-title': 'Check and analyse the DNS records of your domain',
    'meta-description': 'Use IntoDNS to scan, verify, and generate DNS records reports about a domain. It is an ideal analysis tool for web administrators',
    'main-title': 'What is IntoDNS?',
    'main-description':
            '<strong>IntoDNS</strong> is a <strong>DNS</strong> diagnostic and <strong>DNS records</strong> query tool that allows you to know the status of your <strong>domain</strong> and its <strong>DNS records</strong>.<br><br><strong>IntoDNS</strong> is especially designed for web administrators who need to know the <strong>DNS configuration</strong> of any of their <strong>domains</strong> or to check <strong>third-party domains</strong>',
    'how-works-title': 'How does it work?',
    features: {
      'dns-scan': {
        title: 'DNS Scan',
        description:
                    'We scan the DNS servers of the domain you want and show relevant information about the records'
      },
      'dns-check': {
        title: 'DNS Check',
        description:
                    'We check that the data obtained in the DNS scan is correct and reliable'
      },
      'dns-info': {
        title: 'DNS Report',
        description:
                    'We build a report with the data obtained from your domain\'s DNS servers'
      }
    },
    'stats-title': 'IntoDNS Statistics',
    'stats-subtitle':
            'Below you can see some usage statistics of IntoDNS',
    'api-title': 'IntoDNS API',
    'api-description': 'If you want to use IntoDNS technology in your projects, you can integrate your programs and services with our API. You will have access to all the functions of IntoDNS to automate DNS analysis and testing.',
    'api-pre-go-to': 'Our API is publicly and freely accessible. If you want to know more, go to:',
    'go-to-docs-api-version': 'API Documentation V{version}'
  },
  analysis: {
    'copy-section-link': 'Copy link to section',
    'link-copied': 'Link copied',
    'aria-label-copy-to-dns-hosts-section': 'Copy link to the section "DNS Hosts Trace"',
    'aria-label-copy-to-general-tests-section': 'Copy link to the section "General Tests"',
    'aria-label-copy-to-ns-records-section': 'Copy link to the section "NS Records"',
    'aria-label-copy-to-soa-record-section': 'Copy link to the section "SOA Record"',
    'aria-label-copy-to-target-records-section': 'Copy link to the section "A / AAAA / CNAME Records"',
    'aria-label-copy-to-mx-records-section': 'Copy link to the section "MX Records"',
    'aria-label-copy-to-txt-records-section': 'Copy link to the section "TXT Records"',
    'aria-label-copy-to-spf-record-section': 'Copy link to the section "SPF Record"',
    'aria-label-copy-to-dmarc-record-section': 'Copy link to the section "DMARC Record"',
    'aria-label-copy-to-caa-records-section': 'Copy link to the section "CAA Records"',
    'head-title': 'Analysis and report for the domain {domain}',
    'meta-description': 'Analysis and report on the DNS records and configuration of the selected domain',
    'view-datails': 'View details',
    'hide-datails': 'Hide details',
    yes: 'Yes',
    no: 'No',
    empty: 'Empty',
    'show-test-flags': 'View test flags',
    'hide-test-flags': 'Hide test flags',
    'visible-details': 'Visible details',
    'hidden-details': 'Hidden details',
    tests: 'Tests',
    'ns-records': 'NS Records',
    'soa-record': 'SOA Record',
    'mx-records': 'MX Records',
    'spf-record': 'SPF Record',
    'dmarc-record': 'DMARC Record',
    'target-records': 'A / AAAA / CNAME Records',
    'txt-records': 'TXT Records',
    'caa-records': 'CAA Records',
    'results-of': 'Results of {name}',
    'results-table': 'Results table',
    'trace-hosts': 'Trace DNS hosts',
    'go-to-section': 'Go to section \'{section}\'',
    records: 'Records',
    'record-status': 'Status',
    'record-details': 'Details',
    'analysis-result': 'Analysis result',
    'record-test-flags': 'Test flags',
    'record-is-correctly-in-all-tests':
            'No errors detected with this record',
    'parent-requested-for-analysis':
            'Authoritative DNS server selected for DNS queries of the analysis',
    'irresolvable-host': 'Unsolvable host',
    'no-authoritative-hosts-found':
            'No authoritative DNS servers with information about the domain were found. When this happens, you may be trying to query a non-existent domain, an undelegated subdomain, or a non-existent subdomain',
    'test-successful': 'Tests completed',
    'not-resolved': 'Not resolved',
    category: 'Category',
    status: 'Status',
    test: 'Test',
    information: 'Information',
    retry: 'Retry',
    'unexpected-error-in-test':
            'The test could not be completed due to an unexpected error',
    'records-has-recommended-ttl': 'The records have a recommended TTL',
    'records-has-recommended-ttl-test-not-applicable-message': 'This test is not applicable as there are no records to evaluate',
    'any-record-not-recovered': 'No record could be recovered',
    'zero-records-have-been-recovered': '0 records have been recovered',
    'error-in-records-fetch': 'Error trying to fetch records',
    ip: 'IP',
    ips: 'IPs',
    ipv4: 'IPv4',
    ipv6: 'IPv6',
    host: 'Host',
    target: 'Target',
    priority: 'Priority',
    type: 'Type',
    tag: 'Tag',
    flags: 'Flags',
    value: 'Value',
    ptr: 'PTR',
    ttl: 'TTL',
    terms: 'Terms',
    'deploy-correct-tests': 'Deploy correct tests',
    'hide-correct-tests': 'Hide correct tests',
    'there-are-n-correct-tests-hidden':
            'There are <span class="font-bold">{nCorrectTests}</span> correct tests hidden',
    'general-tests': 'General tests',
    'not-has-any-authority-host-correctly-configured-for-continue-analysis':
            'No authoritative DNS server correctly configured was found. This may be due to no authoritative DNS server found in the trace responding to DNS requests or having no information about the domain',
    'unresolvable-host': 'This host does not resolve DNS requests',
    'not-has-soa-record': 'This DNS server does not have the domain\'s SOA record',
    'not-has-ns-record': 'This DNS server does not have the domain\'s NS records',
    general: {
      'less-info': 'Less information',
      'more-info': 'More information',
      'the-zone-should-not-be-transferable':
                'The zone should not be transferable in any authoritative DNS (AXFR)',
      'the-authority-hosts-are-quivalent-to-ns-records':
                'The authoritative DNS are equivalent to the NS records',
      'all-authority-hosts-has-same-soa':
                'All authoritative DNS servers must have the same SOA',
      'recommended-number-of-authority-hosts': 'Recommended number of authoritative DNS servers',
      'dnssec-protection': 'DNSSEC Protection'
    },
    commons: {
      'records-target-has-resolvable-host-ips':
                'The records point to hosts with resolvable IPs'
    },
    NS: {
      'domain-ns-records': 'Domain NS Records',
      'ns-not-present-in-the-trace-have-the-same-soa-as-authority-hosts':
                'NS servers not found in the authoritative DNS have the same SOA record',
      'ns-not-present-in-the-trace-is-not-transferible':
                'NS servers not found in the authoritative DNS should not be transferable (AXFR)'
    },
    SOA: {
      'not-found': 'SOA record not found',
      'mname-term-is-real-authority-host': "The 'mname' term is one of the authoritative DNS servers",
      'serial-term-is-correctly-configured': 'Serial term correctly configured',
      'refresh-term-is-correctly-configured':
                'Refresh term correctly configured',
      'retry-term-is-correctly-configured': 'Retry term correctly configured',
      'expiry-term-is-correctly-configured': 'Expiry term correctly configured',
      'minimum-term-is-correctly-configured':
                'Minimum term correctly configured',
      info: {
        mname:
                    'Primary DNS server of the DNS cluster and primary source of DNS zone information',
        rname: 'Contact email of the DNS zone administrator',
        serial:
                    'Number identifying a specific version of the DNS zone. A zone transfer maintains this value',
        refresh:
                    'Time that secondary DNS servers should wait before requesting changes from the primary DNS server',
        retry:
                    'Time that DNS servers should wait before retrying a zone update after a failure',
        expiry:
                    'Time after which secondary servers that have not been able to update their zone stop responding to DNS queries',
        minimum: 'Time to live (TTL) for negative cache responses'
      }
    },
    'target-hosts': {
      'domain-a-aaaa-cname-records': 'Domain A, AAAA, and CNAME records'
    },
    MX: {
      'domain-mx-records': 'Domain MX Records',
      'no-records-points-to-cname': 'No record points to a CNAME record',
      'there-must-not-be-duplicated-records': 'There must be no duplicated records'
    },
    TXT: {
      'domain-txt-records': 'Domain TXT Records'
    },
    SPF: {
      includes: 'Includes',
      'redirect-to': 'Redirect',
      'authorise-records-a': 'Authorises the domain\'s A records',
      'authorise-records-mx': 'Authorises the domain\'s MX records',
      'spf-recovered-but-error-in-process':
                'The SPF record could be recovered but not processed correctly',
      'authorise-ptr': 'Authorises the PTR of the domain\'s IP',
      EXISTS: 'EXISTS',
      A: 'A',
      MX: 'MX',
      PTR: 'PTR',
      EXP: 'EXP',
      ALL: 'ALL',
      'not-found': 'The SPF record was not found among the TXT records',
      'only-one-record-and-correct-format':
                'There should be only one record with the correct format',
      'a-records-can-send-mails':
                'Hosts of the A and AAAA records are authorised to send emails with the domain name',
      'mx-records-can-send-mails':
                'Hosts of the MX records are authorised to send emails with the domain name',
      'all-term-is-correctly-configured': 'All term correctly configured',
      'ptr-mechanism-use-not-recommended': 'PTR mechanism use not recommended',
      info: {
        'authorise-own-a-records':
                    'Authorises the corresponding hosts of the A / AAAA records of the checked value',
        'authorise-own-mx-records':
                    'Authorises the corresponding hosts of the MX records of the checked value',
        'authorise-own-ptr':
                    'Authorises the corresponding host of the PTR record of the checked value\'s IP',
        a: 'Authorises the corresponding hosts of the A / AAAA record',
        mx: 'Authorises the corresponding hosts of the MX record',
        ptr: 'Authorises the corresponding host of the PTR record',
        ip: 'Authorises the IPv4 or IPv6 indicated in the term itself',
        include: 'Verifies the host against additional SPF records',
        redirect:
                    'When no previous term validates, redirects the check to the indicated host. The query domain becomes the redirection domain when applied',
        exp:
                    "Sets the TXT record containing the response when the result is 'fail'",
        exists:
                    'Allows arbitrary checks through an expression that validates the format of the hosts to be evaluated',
        all:
                    "Default action when no other term validates. When it exists, 'redirect' is ignored"
      }
    },
    DMARC: {
      'not-found':
                'The DMARC record was not found among the TXT records of the subdomain _dmarc.{domain}',
      'requested-domain':
                'The subdomain <span class="text-white">_dmarc.{domain}</span> was queried',
      'only-one-record-and-correct-format':
                'There should be only one record with the correct format',
      'policy-term-correctly-configured':
                'Policy term correctly configured',
      'subdomain-policy-term-correctly-configured':
                'Subdomain policy term correctly configured',
      'pct-term-correctly-configured':
                'PCT term correctly configured',
      'rua-and-ruf-term-correctly-configured':
                'RUA and RUF terms correctly configured',
      'fo-term-correctly-configured':
                'FO term correctly configured',
      'adkim-term-correctly-configured':
                'ADKIM term correctly configured',
      'aspf-term-correctly-configured':
                'ASPF term correctly configured',
      info: {
        p: 'Specifies the policy to follow when validation fails',
        rua:
                    'URI or URIs to send aggregated validation activity reports',
        ruf:
                    'URI or URIs to send forensic reports of specific validation failures',
        adkim:
                    'Specifies the alignment level required between the DKIM and the From domain',
        aspf:
                    'Specifies the alignment level required between the SPF and the From domain',
        fo:
                    'Indicates the type of validation failure necessary to generate a forensic report',
        pct: "Percentage of application of 'quarantine' or 'reject' policies",
        rf: 'Specifies the format used for the forensic reports sent',
        ri:
                    'Interval of time (maximum) between aggregated reports, required to recipient servers',
        sp:
                    "Equivalent to 'p', but applicable only to subdomains. If it does not exist, 'p' applies"
      }
    },
    CAA: {
      'accepted-certifying-bodies': 'Accepted certifying bodies',
      'accept-all-certifying-bodies-message': 'As no restriction records were found (with tag \'issue\' or \'issuewild\'), any certifying body is accepted',
      'deny-all-certifying-bodies-message': 'Having only one empty restriction record (with tag \'issue\' or \'issuewild\') restricts any certifying body'
    },
    accesibility: {
      'alt-email-icon': 'Email icon',
      'aria-label-copy-btn': 'Copy record content'
    },
    'what-is': "What is '{key}'?"
  },
  'feedback-form': {
    title: 'Suggestions and error reporting',
    description: 'You can send us suggestions or notify us of any error you have found',
    'email-contact': 'Contact email',
    'email-place-holder': "example{'@'}example.com",
    'email-aria-label': 'Contact email for the suggestions and error reporting form',
    'issue-description': 'Description of the suggestion or the error found',
    'issue-description-place-holder': 'Write your message here',
    'description-aria-label': 'Description of the suggestion or error found for the suggestions and error reporting form',
    send: 'Send',
    'head-title': 'Suggestions and error reporting',
    'meta-description': 'Form to send suggestions and error reporting',
    'send-btn-aria-label': 'Send button for the suggestions and error reporting form',
    'go-to-feedback-btn-aria-label': 'Go to the Suggestions and error reporting form'
  }
}
