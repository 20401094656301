import data from './cerifying-bodies.json'
import defaultIcon from '../assets/images/certifying_bodies/default.svg'
import google from '../assets/images/certifying_bodies/google.svg'
import apple from '../assets/images/certifying_bodies/apple.svg'
import microsoft from '../assets/images/certifying_bodies/microsoft.svg'
import letsencrypt from '../assets/images/certifying_bodies/letsencrypt.svg'
import digicert from '../assets/images/certifying_bodies/digicert.svg'
import sectigo from '../assets/images/certifying_bodies/sectigo.svg'
import comodoca from '../assets/images/certifying_bodies/comodoca.png'
import entrust from '../assets/images/certifying_bodies/entrust.svg'
import geotrust from '../assets/images/certifying_bodies/geotrust.jpeg'
import globalsign from '../assets/images/certifying_bodies/globalsign.svg'
import thawte from '../assets/images/certifying_bodies/thawte.png'
import ssl from '../assets/images/certifying_bodies/ssl.svg'

interface CertifyingBody {
  icon: string
  domains: string[]
}

const mappedCertifyingBodies = {}

function boot (): void {
  const certifyingBodies: CertifyingBody[] = Object.values(data)

  for (const body of certifyingBodies) {
    for (const domain of body.domains) {
      mappedCertifyingBodies[domain] = body.icon
    }
  }
}

function getIconFromDomain (domain: string): string {
  switch (mappedCertifyingBodies[domain]) {
    case 'google':
      return google
    case 'apple':
      return apple
    case 'microsoft':
      return microsoft
    case 'letsencrypt':
      return letsencrypt
    case 'digicert':
      return digicert
    case 'sectigo':
      return sectigo
    case 'comodoca':
      return comodoca
    case 'entrust':
      return entrust
    case 'geotrust':
      return geotrust
    case 'globalsign':
      return globalsign
    case 'thawte':
      return thawte
    case 'ssl':
      return ssl
    default :
      return defaultIcon
  }
}

export default {
  boot, getIconFromDomain
}
