import { CanceledError, type AxiosHeaderValue, type AxiosResponse } from 'axios'
import type { TestResult } from '../../../models/Interfaces'
import apiService from '../../../services/APIService'
import type State from '../../State'
import { ResponseError } from '../../../errors/Errors'
import { errorLog, infoLog } from '../../../helpers/AppHelper'

function runTest (
  state: State,
  testName: string,
  testServerName: string,
  params: any = {},
  domain: string,
  targetHostname: string | null,
  typeTag: string,
  abortSignal: AbortSignal | undefined,
  onFinallyCallback: () => void
): void {
  state[testName] = undefined

  apiService.fetchTest(
    domain,
    targetHostname,
    typeTag,
    testServerName,
    params,
    abortSignal
  )
    .then((response) => {
      state[testName] = response.data
      injectResponseHeadersInTest((state[testName] as TestResult), response)
    })
    .catch((error) => {
      state[testName] = null

      if (error instanceof ResponseError) {
        errorLog(error)
      } else if (error instanceof CanceledError) {
        infoLog(`Test '${testServerName}' of type '${typeTag}' request cancelled`)
      }
    })
    .finally(() => {
      onFinallyCallback()
    })
}

function injectResponseHeadersInTest (test: TestResult, response: AxiosResponse<any, any>): void {
  test['processing-time'] = response.headers['x-processing-time'] ?? '-'
  test['dns-query-count'] = parseInt((response.headers['x-dns-query-count'] as AxiosHeaderValue)?.toString() ?? '0')
}

export default {
  runTest,
  injectResponseHeadersInTest
}
