import type { AxiosResponse } from 'axios'
import apiService from '../services/APIService'
import State from './State'
import { ResponseMultipleErrors } from '../errors/Errors'

export default class FeedbackFormState extends State {
  public isCompleted: boolean
  public isSending: boolean

  public email: string | undefined
  public message: string | undefined
  public recaptchaToken: string | undefined

  public successfulMessage: string | undefined
  public errorMessages: Record<string, string[]> | undefined

  public start (): void {
    super.start()
    this.isCompleted = false
    this.isSending = false
  }

  public async sendForm (): Promise<void> {
    this.isSending = true
    this.successfulMessage = undefined
    this.errorMessages = undefined

    let response: AxiosResponse<any, any> | undefined

    try {
      response = await apiService.sendFeedback(this.email, this.message, this.recaptchaToken)
    } catch (error) {
      if (error instanceof ResponseMultipleErrors) {
        this.errorMessages = error.errors
      }

      this.isCompleted = true
      this.isSending = false
      return
    }

    this.successfulMessage = response.data?.message
    this.isCompleted = true
    this.isSending = false
  }
}
