import { CanceledError } from 'axios'
import { errorLog, infoLog } from '../../helpers/AppHelper'
import type { TXTRecord, TestResult } from '../../models/Interfaces'
import apiService from '../../services/APIService'
import type AnalysisState from '../AnalysisState'
import RecordState from './RecordState'
import type { HasRecordsHasRecommendedTTLTest } from './mixins/HasRecordsHasRecommendedTTLTest'
import { ResponseError } from '../../errors/Errors'

export default class TXTRecordsState extends RecordState implements HasRecordsHasRecommendedTTLTest {
  public typeTag: string = 'TXT'

  public txtRecords: TXTRecord[] | undefined

  public recordsHasRecommendedTTLTest:
  TestResult
  | null
  | undefined

  protected initialDeclaredLoadStepsValue (): number {
    return 2
  }

  public reset (): void {
    super.reset()
    this.txtRecords = undefined
    this.recordsHasRecommendedTTLTest = undefined
  }

  public start (): void {
    apiService
      .fetchRecords(
        this.domain,
        this.parentHost.host,
        this.typeTag,
        this.getParent<AnalysisState>()?.getAbortSignal()
      )
      .then((response) => {
        this.txtRecords = response.data
        this.hasRecoveredAnyRecord = this.txtRecords !== undefined && this.txtRecords.length > 0
        this.saveRecordsResponseHeaders(response)
      })
      .catch((error) => {
        this.hasErrorsInRecordFetch = true

        if (error instanceof ResponseError) {
          errorLog(error)
        } else if (error instanceof CanceledError) {
          infoLog(`'${this.typeTag}' records request cancelled`)
        }
      })
      .finally(() => {
        this.runTestsIfHasRecoveredAnyRecord()
      })

    super.start()
  }

  protected runTests (): void {
    this.runRecordsHasRecommendedTTLTest()
  }

  public runRecordsHasRecommendedTTLTest (): void {
    this.runTest(
      'recordsHasRecommendedTTLTest',
      'records-has-recommended-ttl-range',
      { records: this.txtRecords },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'txtRecords'
        }
      }
    )
  }

  protected finalize (): void {
    this.isReady = true
    this.getParent()?.notifyChildReady()
  }
}
