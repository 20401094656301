import RecordState from './RecordState'
import apiService from '../../services/APIService'
import type AnalysisState from '../AnalysisState'
import { CanceledError } from 'axios'
import { ResponseError } from '../../errors/Errors'
import { errorLog, infoLog } from '../../helpers/AppHelper'
import type { CAARecord, TestResult } from '../../models/Interfaces'
import type { HasRecordsHasRecommendedTTLTest } from './mixins/HasRecordsHasRecommendedTTLTest'
import { useI18nInstance } from '../../services/I18n'

export default class CAARecordState extends RecordState implements HasRecordsHasRecommendedTTLTest {
  public typeTag: string = 'CAA'

  public caaRecords: CAARecord[] | undefined

  public recordsHasRecommendedTTLTest:
  TestResult
  | undefined
  | null

  private zeroRecordsHaveBeenRecovered: boolean = false

  protected initialDeclaredLoadStepsValue (): number {
    return 2
  }

  public reset (): void {
    super.reset()
    this.caaRecords = undefined
    this.recordsHasRecommendedTTLTest = undefined
    this.zeroRecordsHaveBeenRecovered = false
  }

  public start (): void {
    void apiService
      .fetchRecords(
        this.domain,
        this.parentHost.host,
        this.typeTag,
        this.getParent<AnalysisState>()?.getAbortSignal()
      )
      .then((response) => {
        this.caaRecords = response.data
        this.hasRecoveredAnyRecord = this.caaRecords !== undefined
        this.zeroRecordsHaveBeenRecovered = this.hasRecoveredAnyRecord && this.caaRecords!.length === 0
        this.saveRecordsResponseHeaders(response)
      })
      .catch((error) => {
        this.hasErrorsInRecordFetch = true

        if (error instanceof ResponseError) {
          errorLog(error)
        } else if (error instanceof CanceledError) {
          infoLog(`'${this.typeTag}' records request cancelled`)
        }
      })
      .finally(() => {
        this.runTestsIfHasRecoveredAnyRecord()
      })

    super.start()
  }

  protected runTests (): void {
    this.runRecordsHasRecommendedTTLTest()
  }

  public runRecordsHasRecommendedTTLTest (): void {
    if (this.zeroRecordsHaveBeenRecovered) {
      this.setTestWithFakeResponseNotAplicable('recordsHasRecommendedTTLTest', useI18nInstance().t('analysis.records-has-recommended-ttl-test-not-applicable-message'))
      return
    }

    this.runTest(
      'recordsHasRecommendedTTLTest',
      'records-has-recommended-ttl-range',
      { records: this.caaRecords },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'caaRecords'
        }
      }
    )
  }

  // Cech if has restriction records (issue and issuewild) with restrictions, the empty records (";") dont has domain key in his 'value-sentences'
  public get hasOnlyEmptyRestrictionRecords (): boolean {
    if (this.caaRecords === undefined) {
      return false
    }

    return this.getRestrictionRecords().some((item: CAARecord) => item['value-sentences'].domain !== undefined)
  }

  public getRestrictionRecords (): CAARecord[] {
    if (this.caaRecords === undefined) {
      return []
    }

    return this.caaRecords.filter(this.getCaaRestrictionRecordsFilterClosure())
  }

  public get hasRestrictionRecords (): boolean {
    if (this.caaRecords === undefined) {
      return false
    }

    return this.caaRecords.some(this.getCaaRestrictionRecordsFilterClosure())
  }

  private getCaaRestrictionRecordsFilterClosure (): (record: CAARecord) => boolean {
    return (caaRecord: CAARecord) => caaRecord.tag === 'issue' || caaRecord.tag === 'issuewild'
  }

  protected finalize (): void {
    this.isReady = true
    this.getParent()?.notifyChildReady()
  }
}
