import { useTippy } from 'vue-tippy'
import { useI18nInstance } from '../services/I18n'

export function onCopyToClipInTextField (ev: PointerEvent): void {
  let toolTipText: string = useI18nInstance().t('unexpected-error')

  const targetElement: Element | null = ev.target as Element | null

  if (targetElement === null) {
    errorLog('Error when try recovery event target on onCopyToClipInTextField function')
    return
  }

  if (window.isSecureContext) {
    const spanTextContentElement: HTMLSpanElement | null = targetElement?.previousElementSibling?.firstChild as HTMLSpanElement | null
    void navigator.clipboard.writeText(spanTextContentElement?.innerText ?? '')
    toolTipText = useI18nInstance().t('copied-to-clipboard')
  }

  useTippy(targetElement, {
    content: toolTipText,
    placement: 'top',
    trigger: ''
  })?.show()
}

export function infoLog (message: any): void {
  console.info(`[INFO]: ${message}`)
}

export function errorLog (message: any): void {
  console.error(`[ERROR]: ${message}`)
}
