import apiService from '../../services/APIService'
import RecordState from './RecordState'
import type { NSRecord, TestResult } from '../../models/Interfaces'
import type { HasRecordsHasRecommendedTTLTest } from './mixins/HasRecordsHasRecommendedTTLTest'
import type AnalysisState from '../AnalysisState'
import { CanceledError } from 'axios'
import { ResponseError } from '../../errors/Errors'
import { errorLog, infoLog } from '../../helpers/AppHelper'

export default class NSRecordsState
  extends RecordState
  implements HasRecordsHasRecommendedTTLTest {
  public typeTag: string = 'NS'

  public nsRecords: NSRecord[] | undefined

  private extraNSRecords: NSRecord[] | undefined

  private extraNsRecordsValuesForDNSExtraTests: string [] | undefined

  public hasExtraNSRecordsForDNSExtraTests: boolean = false

  public targetHostsWithResolvableIPsTest:
  TestResult
  | null
  | undefined

  public recordsHasRecommendedTTLTest:
  TestResult
  | undefined
  | null

  public axfrDoesNotResolveOnAnyDNSServerExtraTest:
  TestResult
  | undefined
  | null

  public allAuthorityHostsHasSameSOAExtraTest:
  TestResult
  | undefined
  | null

  protected initialDeclaredLoadStepsValue (): number {
    return 3
  }

  public reset (): void {
    super.reset()
    this.nsRecords = undefined
    this.targetHostsWithResolvableIPsTest = undefined
    this.recordsHasRecommendedTTLTest = undefined
    this.axfrDoesNotResolveOnAnyDNSServerExtraTest = undefined
    this.allAuthorityHostsHasSameSOAExtraTest = undefined
  }

  public start (): void {
    void apiService
      .fetchRecords(
        this.domain,
        this.parentHost.host,
        this.typeTag,
        this.getParent<AnalysisState>()?.getAbortSignal()
      )
      .then((response) => {
        this.nsRecords = response.data
        this.hasRecoveredAnyRecord = this.nsRecords !== undefined && this.nsRecords.length > 0
        this.saveRecordsResponseHeaders(response)
      })
      .catch((error) => {
        this.hasErrorsInRecordFetch = true

        if (error instanceof ResponseError) {
          errorLog(error)
        } else if (error instanceof CanceledError) {
          infoLog(`'${this.typeTag}' records request cancelled`)
        }
      })
      .finally(() => {
        this.runTestsIfHasRecoveredAnyRecord()
      })

    super.start()
  }

  public onExtraNsRecordsReciveForDNSExtraTests (extraNsRecordsValuesForDNSExtraTests: string[]): void {
    this.extraNsRecordsValuesForDNSExtraTests = extraNsRecordsValuesForDNSExtraTests
    this.tryRunDNSExtraTests()
  }

  protected runTests (): void {
    this.runTargetHostsWithResolvableIPsTest()
    this.runRecordsHasRecommendedTTLTest()
    this.tryRunDNSExtraTests()
  }

  private tryRunDNSExtraTests (): void {
    if (this.nsRecords === undefined || !this.hasRecoveredAnyRecord || this.extraNsRecordsValuesForDNSExtraTests === undefined) {
      return
    }

    const extraNSRecords: NSRecord[] = this.nsRecords.filter((record: NSRecord) => this.extraNsRecordsValuesForDNSExtraTests?.includes(record.value))

    if (extraNSRecords.length === 0) {
      return
    }

    this.hasExtraNSRecordsForDNSExtraTests = true

    this.extraNSRecords = extraNSRecords

    this.declaredLoadSteps += 2

    this.runAllAuthorityHostsHasSameSOAExtraTest()
    this.runAxfrDoesNotResolveOnAnyDNSServerExtraTest()
  }

  public runTargetHostsWithResolvableIPsTest (): void {
    this.runTest(
      'targetHostsWithResolvableIPsTest',
      'target-hosts-with-resolvable-ips',
      { records: this.nsRecords },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'nsRecords'
        }
      }
    )
  }

  public runRecordsHasRecommendedTTLTest (): void {
    this.runTest(
      'recordsHasRecommendedTTLTest',
      'records-has-recommended-ttl-range',
      { records: this.nsRecords },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'nsRecords'
        }
      }
    )
  }

  public runAllAuthorityHostsHasSameSOAExtraTest (): void {
    this.runTest(
      'allAuthorityHostsHasSameSOAExtraTest',
      'all-authority-hosts-has-same-soa',
      {
        mode: 'with-extra-ns',
        'output-only-for-ns': true,
        'authority-hosts': this.getParent<AnalysisState>()?.getAuthorityHosts(),
        records: this.extraNSRecords
      },
      {
        typeTag: 'GENERAL',
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'nsRecords',
          specificKeyInTestOutputElements: 'ns',
          useStricMatchMode: false
        }
      }
    )
  }

  public runAxfrDoesNotResolveOnAnyDNSServerExtraTest (): void {
    this.runTest(
      'axfrDoesNotResolveOnAnyDNSServerExtraTest',
      'axfr-does-not-resolve-on-any-dns-server',
      {
        mode: 'ns',
        records: this.extraNSRecords
      },
      {
        typeTag: 'GENERAL',
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'nsRecords',
          specificKeyInTestOutputElements: 'ns',
          useStricMatchMode: false
        }
      }
    )
  }

  protected finalize (): void {
    this.isReady = true
    this.getParent()?.notifyChildReady()
  }
}
