import RecordState from './RecordState'
import apiService from '../../services/APIService'
import type { NSRecord, SOARecord, TestResult } from '../../models/Interfaces'
import type AnalysisState from '../AnalysisState'
import { ResponseError } from '../../errors/Errors'
import { CanceledError } from 'axios'
import { errorLog, infoLog } from '../../helpers/AppHelper'

export default class SOARecordState extends RecordState {
  public typeTag: string = 'SOA'

  public soaRecord: SOARecord | undefined

  public nsRecords: NSRecord[] | undefined

  public mnameIsRealAuthorityHostTest: TestResult | null | undefined

  public serialTermTest: TestResult | null | undefined

  public refreshTermTest: TestResult | null | undefined

  public retryTermTest: TestResult | null | undefined

  public expiryTermTest: TestResult | null | undefined

  public minimumTermTest: TestResult | null | undefined

  protected initialDeclaredLoadStepsValue (): number {
    return 7
  }

  public reset (): void {
    super.reset()
    this.soaRecord = undefined
    this.nsRecords = undefined
    this.mnameIsRealAuthorityHostTest = undefined
    this.serialTermTest = undefined
    this.refreshTermTest = undefined
    this.retryTermTest = undefined
    this.expiryTermTest = undefined
    this.minimumTermTest = undefined
  }

  public start (): void {
    void apiService
      .fetchRecords(
        this.domain,
        this.parentHost.host,
        this.typeTag,
        this.getParent<AnalysisState>()?.getAbortSignal()
      )
      .then((response) => {
        this.soaRecord = response.data[0] ?? null
        this.saveRecordsResponseHeaders(response)
        this.hasRecoveredAnyRecord = this.soaRecord !== null
      })
      .catch((error) => {
        this.hasErrorsInRecordFetch = true

        if (error instanceof ResponseError) {
          errorLog(error)
        } else if (error instanceof CanceledError) {
          infoLog('SOA record request cancelled')
        }
      })
      .finally(() => {
        this.runTestsIfHasRecoveredAnyRecord()
      })

    super.start()
  }

  protected runTests (): void {
    this.runMnameIsRealAuthorityHostTest()
    this.runSerialTermTest()
    this.runRefreshTermTest()
    this.runRetryTermTest()
    this.runExpiryTermTest()
    this.runMinimumTermTest()
  }

  public runMnameIsRealAuthorityHostTest (): void {
    this.runTest('mnameIsRealAuthorityHostTest', 'mname-term-is-real-authority-host', {
      records: [this.soaRecord],
      'authority-hosts': this.getParent<AnalysisState>()?.getAuthorityHosts()
    },
    {
      updateRecordStates: {
        shouldUpdate: true,
        recordsPropertyName: 'soaRecord',
        recordsPropertyIsSingleRecord: true
      }
    })
  }

  public runSerialTermTest (): void {
    this.runTest('serialTermTest', 'serial-term', {
      records: [this.soaRecord]
    },
    {
      updateRecordStates: {
        shouldUpdate: true,
        recordsPropertyName: 'soaRecord',
        recordsPropertyIsSingleRecord: true
      }
    }
    )
  }

  public runRefreshTermTest (): void {
    this.runTest('refreshTermTest', 'refresh-term', {
      records: [this.soaRecord]
    },
    {
      updateRecordStates: {
        shouldUpdate: true,
        recordsPropertyName: 'soaRecord',
        recordsPropertyIsSingleRecord: true
      }
    })
  }

  public runRetryTermTest (): void {
    this.runTest('retryTermTest', 'retry-term', {
      records: [this.soaRecord]
    },
    {
      updateRecordStates: {
        shouldUpdate: true,
        recordsPropertyName: 'soaRecord',
        recordsPropertyIsSingleRecord: true
      }
    })
  }

  public runExpiryTermTest (): void {
    this.runTest('expiryTermTest', 'expiry-term', {
      records: [this.soaRecord]
    },
    {
      updateRecordStates: {
        shouldUpdate: true,
        recordsPropertyName: 'soaRecord',
        recordsPropertyIsSingleRecord: true
      }
    })
  }

  public runMinimumTermTest (): void {
    this.runTest('minimumTermTest', 'minimum-term', {
      records: [this.soaRecord]
    },
    {
      updateRecordStates: {
        shouldUpdate: true,
        recordsPropertyName: 'soaRecord',
        recordsPropertyIsSingleRecord: true
      }
    })
  }

  protected finalize (): void {
    this.isReady = true
    this.getParent()?.notifyChildReady()
  }
}
