import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { createBaseApp } from './boot/app'
import * as Sentry from '@sentry/vue'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { bootFontAwesome } from './boot/font_awesome'
import emailProvidersService from './services/EmailProvidersService'
import certifyingBodiesService from './services/CertifyingBodiesService'

function configAxios (): void {
  axios.defaults.headers.common['X-Frontal'] = '1'
}

// boot
configAxios()
bootFontAwesome()

void createInertiaApp({
  title: (title: string) => `IntoDNS - ${title}`,
  resolve: async (name: string | undefined) => {
    /**
         * In some client cases, it has been detected that the variable name has undefined value,
         * We have not been able to reply this error, but to avoid this bug situation if the name
         * variable coms with falsy value, then asign the main view value.
         */
    name ??= 'MainView'

    return await resolvePageComponent(
                    `./pages/${name}.vue`,
                    (import.meta as any).glob('./pages/**/*.vue')) // eslint-disable-line @typescript-eslint/no-unsafe-argument
  },
  setup ({ el, App, props, plugin }) {
    const [vueApp, state] = createBaseApp(App, props)

    emailProvidersService.boot()
    certifyingBodiesService.boot()

    Sentry.init({
      app: vueApp,
      environment: import.meta.env.VITE_APP_ENV,
      dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
      integrations: [
        Sentry.browserTracingIntegration({
          router: state.getRouter()
        })
      ]
    })

    vueApp
      .component('font-awesome-icon', FontAwesomeIcon)
      .use(plugin)
      .mount(el)

    return vueApp
  }
})
