import { CanceledError } from 'axios'
import { ResponseError } from '../../errors/Errors'
import { errorLog, infoLog } from '../../helpers/AppHelper'
import type { MXRecord, TestResult } from '../../models/Interfaces'
import apiService from '../../services/APIService'
import type AnalysisState from '../AnalysisState'
import RecordState from './RecordState'
import type { HasRecordsHasRecommendedTTLTest } from './mixins/HasRecordsHasRecommendedTTLTest'

export default class MXRecordsState extends RecordState implements HasRecordsHasRecommendedTTLTest {
  public typeTag: string = 'MX'

  public mxRecords: MXRecord[] | undefined

  public targetHostsWithResolvableIPsTest:
  TestResult
  | null
  | undefined

  public duplicatedRecordsTest:
  TestResult
  | null
  | undefined

  public noRecordsPointsToCNAMETest:
  TestResult
  | null
  | undefined

  public recordsHasRecommendedTTLTest:
  TestResult
  | null
  | undefined

  protected initialDeclaredLoadStepsValue (): number {
    return 5
  }

  public reset (): void {
    super.reset()
    this.mxRecords = undefined
    this.targetHostsWithResolvableIPsTest = undefined
    this.duplicatedRecordsTest = undefined
    this.noRecordsPointsToCNAMETest = undefined
    this.recordsHasRecommendedTTLTest = undefined
  }

  public start (): void {
    apiService
      .fetchRecords(
        this.domain,
        this.parentHost.host,
        this.typeTag,
        this.getParent<AnalysisState>()?.getAbortSignal()
      )
      .then((response) => {
        this.mxRecords = response.data
        this.hasRecoveredAnyRecord = this.mxRecords !== undefined && this.mxRecords.length > 0
        this.saveRecordsResponseHeaders(response)
      })
      .catch((error) => {
        this.hasErrorsInRecordFetch = true

        if (error instanceof ResponseError) {
          errorLog(error)
        } else if (error instanceof CanceledError) {
          infoLog(`'${this.typeTag}' records request cancelled`)
        }
      })
      .finally(() => {
        this.getParent<AnalysisState>()?.onMXRecordsRecive(this.mxRecords ?? null)
        this.runTestsIfHasRecoveredAnyRecord()
      })

    super.start()
  }

  protected runTests (): void {
    this.runTargetHostsWithResolvableIPsTest()
    this.runDuplicatedRecordsTest()
    this.runNoRecordsPointsToCNAMETest()
    this.runRecordsHasRecommendedTTLTest()
  }

  public runTargetHostsWithResolvableIPsTest (): void {
    this.runTest(
      'targetHostsWithResolvableIPsTest',
      'target-hosts-with-resolvable-ips',
      { records: this.mxRecords },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'mxRecords'
        }
      }
    )
  }

  public runDuplicatedRecordsTest (): void {
    this.runTest(
      'duplicatedRecordsTest',
      'duplicated-records',
      { records: this.mxRecords },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'mxRecords'
        }
      }
    )
  }

  public runNoRecordsPointsToCNAMETest (): void {
    this.runTest(
      'noRecordsPointsToCNAMETest',
      'no-records-points-to-cname',
      { records: this.mxRecords },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'mxRecords'
        }
      }
    )
  }

  public runRecordsHasRecommendedTTLTest (): void {
    this.runTest(
      'recordsHasRecommendedTTLTest',
      'records-has-recommended-ttl-range',
      { records: this.mxRecords },
      {
        updateRecordStates: {
          shouldUpdate: true,
          recordsPropertyName: 'mxRecords'
        }
      }
    )
  }

  protected finalize (): void {
    this.isReady = true
    this.getParent()?.notifyChildReady()
  }
}
